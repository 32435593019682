<template>
  <div
    v-show="visible"
    :class="className"
    :style="style"
  >
    <MDBSpinner />
  </div>
</template>
<script setup>
import { MDBSpinner } from 'mdb-vue-ui-kit';
import { computed } from 'vue';

const visible = computed(() => window.$store.state.Loading);
const className = computed(() => [
  'align-items-center',
  visible.value && 'd-flex',
  'justify-content-center',
  'vh-100',
  'vw-100',
]);
const style = 'position: fixed; z-index: 999;';
</script>
