<template>
  <div>
    <CommonGrid
      class-name="server-status"
      :columns="columns"
      :datas="gridItems"
    >
      <template
        #is_live="{is_live}"
      >
        <div
          class="acco-status"
          :class="{'text-danger' : !is_live }"
        >
          {{ is_live ? '정상' : '비정상' }}
        </div>
      </template>
    </CommonGrid>
  </div>
</template>
<script setup>
import CommonGrid from '@/components/CommonGrid.vue';
import { computed } from 'vue';
import { STORE_SETUP_CHANNEL } from '@/store/modules/SetupChannel';

const columns = Object.freeze([

  {
    field: 'group_name',
    title: '그룹',
    headerClassName: 'acco-group',
    className: 'acco-group',
    sortable: false,
  },
  {
    field: 'server_name',
    title: '서버',
    headerClassName: 'acco-server',
    className: 'acco-server',
    sortable: false,
  },
  {
    field: 'url',
    title: 'URL',
    headerClassName: 'acco-url',
    className: 'acco-url',
    sortable: false,
  },
  {
    field: 'is_live',
    title: '동작상태',
    headerClassName: 'acco-status',
    className: 'acco-status',
    sortable: false,
  },
]);
const gridItems = computed(() => window.$store.getters[`${STORE_SETUP_CHANNEL}/healths`] || []);
</script>
<style lang="scss">
.server-status{
  margin-bottom: 2rem;
  .accoItem{
    justify-content: space-between;
    &.accoHeader .tit:first-child,
    .acco-group,
    .acco-server{
      flex: 0 0 10%;
      min-width: 100px;
    }
    .acco-url,
    .acco-status{
      flex: 1 0 20%;
      min-width: 200px;
    }
  }
}
</style>
