<template>
  <div class="gridControlWrap">
    <div class="searchWrap">
      <CommonDatepicker v-model="date" />
      <div class="searchBox">
        <CommonInput
          label="자막파일명"
          v-model="refContentName"
        />
        <CommonInput
          label="채널명"
          v-model="refChannel"
          @keyup.enter="getList()"
        />
        <CommonButton
          class="btnSearch"
          @click="onSearchClick()"
        >
          검색
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import CommonDatepicker from '@/components/CommonDatepicker.vue';
import CommonButton from '@/components/CommonButton.vue';
import CommonInput from '@/components/CommonInput.vue';

import useMappingCaptionSearch from './mappingCaptionSearch';

const {
  date,
  getList,
  refContentName,
  refChannel,
} = useMappingCaptionSearch();

const emit = defineEmits(['selection-changed']);

function onSearchClick() {
  getList();
  emit('selection-changed', null);
}

</script>
<style lang="scss" scoped>
.searchBox{
  display: flex;
  align-items: center;
  .sort-term{
    margin:0 0.5rem 0 0;
  }
}
</style>
