import { ref, computed } from 'vue';
import moment from 'moment';
import { STORE_CAPTION_SEARCH } from '@/store/modules/CaptionSearch';

export default function useMappingCaptionSearch() {
  const searchFromDate = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'search', 'brcast_start_dt');
  const searchToDate = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'search', 'brcast_end_dt');

  const searchContentName = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'search', 'caption_nm');
  const searchChannel = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'search', 'channel_nm');
  const refCurrentPage = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'search', 'offset_page');
  const refContentName = ref(searchContentName);
  const refChannel = ref(searchChannel);

  const date = computed({
    get() {
      const searchFromDt = moment(searchFromDate.value);
      const searchToDt = moment(searchToDate.value);
      return [
        searchFromDt.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        searchToDt.hour(23)
          .minute(59)
          .second(0)
          .millisecond(0)
          .toDate(),
      ];
    },
    set(value) {
      const from = value[0]?.valueOf();
      const fromYYYYMMDD = moment(from).format('YYYYMMDD');

      const to = value[1]?.valueOf();
      const toYYYYMMDD = moment(to).format('YYYYMMDD');
      if (fromYYYYMMDD !== searchFromDate.value || toYYYYMMDD !== searchToDate.value) {
        window.$store.commit(`${STORE_CAPTION_SEARCH}/search`, {
          brcast_start_dt: fromYYYYMMDD,
          brcast_end_dt: toYYYYMMDD,
        });
      }
    },
  });

  function getList(page) {
    refCurrentPage.value = page || 1;
    window.$store.dispatch(`${STORE_CAPTION_SEARCH}/getList`);
  }

  return {
    date,
    refContentName,
    getList,
    refChannel,
  };
}
