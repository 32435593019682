<template>
  <Authenticator>
    <template #header>
      <AuthHeader />
    </template>
    <template #footer>
      <AuthFooter />
    </template>
    <slot />
  </Authenticator>
</template>

<script setup>
import { watch } from 'vue';
import { Authenticator, translations } from '@aws-amplify/ui-vue';
import {
  I18n, Hub, Auth,
} from 'aws-amplify';

import useAuthenticator from '@/composables/useAuthenticator';

import '@aws-amplify/ui-vue/styles.css';

import AuthHeader from '@/components/auth/AuthHeader.vue';
import AuthFooter from '@/components/auth/AuthFooter.vue';

I18n.putVocabularies(translations);
I18n.setLanguage('kr');

const { isPending } = useAuthenticator();

watch(isPending, (newVal) => {
  window.$util.Loading(newVal);
});

Hub.listen('auth', (data) => {
  const { payload } = data;
  if (payload.event === 'signIn') {
    Auth.currentSession()
      .then((sessionData) => {
        window.localStorage.jwtToken = sessionData.accessToken?.jwtToken;
        window.$util.HealthCheck();
      })
      .catch((e) => console.log(e));
  }
  if (payload.event === 'signOut') {
    window.localStorage.jwtToken = undefined;
  }
});

</script>

<style lang="scss">
.amplify-button--primary{
  background-color: #FF9000;
  border-radius: 4px !important;
  &:hover{
    background-color: #FF5A20;
  }
}
.amplify-tabs-item[data-state=active],
.amplify-button--link{
  border-color: #FF9000;
  color: #FF5A20;
}
[data-indicator-position=top]>.amplify-tabs-item{
  border-width: 4px 0 0 0;
}

[data-amplify-authenticator] [data-amplify-router]{
  border-radius: 4px;
  border-color: #e4e4e4;
}
.amplify-label {
    display: none;
}
.amplify-field{
  :not(:first-child){
    .amplify-input{
        border-start-start-radius: 4px;
        border-end-start-radius: 4px;
        text-align: left;
    }
  }
  &.amplify-passwordfield{
    .amplify-input{
      border-start-end-radius: 0px;
      border-end-end-radius: 0px;
    }
  }
}

</style>
