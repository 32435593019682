<template>
  <div class="d-flex align-items-center gap-2 my-3">
    스코어
    <CommonInput
      type="number"
      label=""
      class="d-inline"
      :disabled="refDisable"
      v-model="inputScore"
      @input="validateScore"
    />
    <CommonButton
      class="btnSearch btn-outline-dark"
      @click="onClickModifyScore"
    >
      {{ buttonTitle }}
    </CommonButton>
  </div>
</template>
<script setup>
import CommonInput from '@/components/CommonInput.vue';
import CommonButton from '@/components/CommonButton.vue';
import { computed, ref } from 'vue';
import { STORE_SETUP_CHANNEL } from '@/store/modules/SetupChannel';

const refDisable = ref(true);
const buttonTitle = computed(() => (refDisable.value ? '수정' : '저장'));

const score = computed(() => window.$store.getters[`${STORE_SETUP_CHANNEL}/score`]);
const inputScore = ref(score.value);

function validateScore(input) {
  const newValue = Number(input.target.value);
  if (input.data === '.' || newValue > 99 || newValue < 0) {
    // eslint-disable-next-line no-param-reassign
    input.target.value = inputScore.value;
  }
}
async function requestModifyRatio() {
  const res = await window.$axios.patch('setups/validations/score', {
    score: Number(inputScore.value),
  });
  const {
    code,
    message,
  } = await res.result;
  if (code !== 200) {
    window.$util.Error({
      url: 'setups/validations/score',
    });
    throw new Error(`${message} while requesting setups/validations/score`);
  }
  window.$util.Toast('설정이 변경되었습니다.');
  window.$store.commit(`${STORE_SETUP_CHANNEL}/setState`, {
    score: res.score,
  });
  refDisable.value = true;
}

function onClickModifyScore() {
  if (refDisable.value) {
    refDisable.value = false;
  } else {
    window.$util.Confirm('자막검수툴 설정', `스코어를 ${inputScore.value}로 수정하시겠습니까?`, requestModifyRatio);
  }
}

</script>
<style lang="scss">
.text-rate{
  margin-right: 0.5rem;
}
.btnSearch{
  font-weight: bold;
}
</style>
