<template>
  <div class="gridControlWrap multi">
    <div class="searchWrap ">
      <CommonDatepicker
        v-model="date"
      />
    </div>
    <div class="searchWrap">
      <div class="searchBox">
        <CommonSelect
          v-model:options="RESULT_TYPE"
          v-model:selected="refResultType"
          placeholder="결과 선택"
        />
        <CommonSelect
          v-model:options="SEARCH_TYPE"
          v-model:selected="refSearchType"
          placeholder="통합검색"
        />
        <CommonInput
          label="에피소드"
          v-model="refContentName"
          @keyup.enter="getList()"
        />
        <CommonInput
          label="채널명"
          v-model="refChannel"
          @keyup.enter="getList()"
        />
        <CommonButton
          class="btnSearch"
          @click="getList()"
        >
          검색
        </CommonButton>
        <CommonButton
          class="px-3 py-2 btn-tool"
          color="warning"
          @click="requestDownload"
          icon="file-archive"
          icon-style="fas"
          :disabled="!downloadable"
        >
          다운로드
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import CommonDatepicker from '@/components/CommonDatepicker.vue';
import CommonButton from '@/components/CommonButton.vue';
import CommonInput from '@/components/CommonInput.vue';
import CommonSelect from '@/components/CommonSelect.vue';
import useEncoderHistorySearch, { STORE_SYNC_SEARCH } from '@/views/SyncSearch/syncSearchSearch';
import { computed, ref } from 'vue';

const checkedObj = computed(() => window.$store.getters[`${STORE_SYNC_SEARCH}/checkedItems`]);
const downloadable = computed(() => (!!checkedObj.value.length));

const RESULT_TYPE = ref([
  {
    text: '처리상태', value: undefined,
  },
  {
    text: '동기화진행', value: 'SYU002',
  },
  {
    text: '동기화중지', value: 'SYU006',
  },
  {
    text: '동기화실패', value: 'SYU007',
  },
  {
    text: '검수대기', value: 'SYU003',
  },
  {
    text: '검수진행중', value: 'SYU004',
  },
  {
    text: '자막완료 ', value: 'SYU005',
  },
]);

const SEARCH_TYPE = ref([
  {
    text: '에피소드명', value: 'TST001',
  },
  {
    text: '에피소드ID', value: 'TST002',
  },
]);

const {
  date,
  refContentName,
  refResultType,
  refSearchType,
  refChannel,
  getList,
  requestDownload,
} = useEncoderHistorySearch();

</script>

<style>
.multi{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
}
</style>
