import { computed } from 'vue';
import { STORE_CAPTION_SEARCH } from '@/store/modules/CaptionSearch';
import moment from 'moment/moment';

export default function useMappingCationGrid() {
  const columns = Object.freeze([
    {
      field: 'checked',
      title: '선택',
      headerClassName: 'acco-select',
      className: 'acco-select',
    },
    {
      field: 'no',
      title: 'No',
      headerClassName: 'accoTime',
      className: 'accoTime',
      sortable: false,
    },
    {
      field: 'brcast_dt',
      title: '방송일',
      headerClassName: 'accoTime',
      className: 'accoTime',
      sortable: false,
    },
    {
      field: 'caption_file_nm',
      title: '자막파일명',
      headerClassName: 'accoContents',
      className: 'accoContents',
      sortable: false,
    },

    {
      field: 'channel_nm',
      title: '채널',
      headerClassName: 'accoChannel',
      className: 'accoChannel',
      sortable: false,
    },
    {
      field: 'brcast_epsd_no',
      title: '회차',
      headerClassName: 'accoTime',
      className: 'accoTime',
      sortable: false,
    },

  ]);

  // const ID_FIELD = 'caption_contents_seq';

  const refCurrentPage = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'search', 'offset_page');
  const checkedObj = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'grid', 'checked');

  const totalPage = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'pagination', 'total_page_num');
  const limit = window.$util.computeGetSet(STORE_CAPTION_SEARCH, 'pagination', 'limit');
  const dataItems = computed(() => window.$store.getters[`${STORE_CAPTION_SEARCH}/dataItems`] || []);

  const gridItems = computed(() => {
    const startNum = limit.value * (refCurrentPage.value - 1) + 1;
    return dataItems.value.map((obj, idx) => ({
      no: startNum + idx,
      ...obj,
      brcast_dt: moment(obj.brcast_dt).format('YYYY-MM-DD'),
    }));
  });

  const getList = () => window.$store.dispatch(`${STORE_CAPTION_SEARCH}/getList`);
  return {
    columns,
    checkedObj,
    refCurrentPage,
    gridItems,
    totalPage,
    onChangePage(idx) {
      refCurrentPage.value = idx;
      getList();
    },
  };
}
