import { computed } from 'vue';
import { STORE_UNMAPPED_CAPTION } from '@/store/modules/UnmappedCaption';
import moment from 'moment';

export default function useMappingCationGrid() {
  const columns = Object.freeze([
    {
      field: 'checked',
    },
    {
      field: 'no',
      title: 'No',
      headerClassName: 'acco-number',
      className: 'acco-number',
      sortable: false,
    },
    {
      field: 'brcast_dt',
      title: '방송일',
      headerClassName: 'acco-date',
      className: 'acco-date',
      sortable: false,
    },
    {
      field: 'caption_file_nm',
      title: '자막파일명',
      headerClassName: 'acco-filename',
      className: 'acco-filename',
      sortable: false,
    },

    {
      field: 'channel_nm',
      title: '채널',
      headerClassName: 'acco-chanel',
      className: 'acco-chanel',
      sortable: false,
    },
    {
      field: 'brcast_epsd_no',
      title: '회차',
      headerClassName: 'acco-episodenumber',
      className: 'acco-episodenumber',
      sortable: false,
    },

  ]);

  // const ID_FIELD = 'caption_contents_seq';

  const refCurrentPage = window.$util.computeGetSet(STORE_UNMAPPED_CAPTION, 'search', 'offset_page');
  const checkedObj = window.$util.computeGetSet(STORE_UNMAPPED_CAPTION, 'grid', 'checked');

  const totalPage = window.$util.computeGetSet(STORE_UNMAPPED_CAPTION, 'pagination', 'total_page_num');
  const limit = window.$util.computeGetSet(STORE_UNMAPPED_CAPTION, 'pagination', 'limit');

  const dataItems = computed(() => window.$store.getters[`${STORE_UNMAPPED_CAPTION}/dataItems`] || []);

  const gridItems = computed(() => {
    const startNum = limit.value * (refCurrentPage.value - 1) + 1;
    return dataItems.value.map((obj, idx) => ({
      no: startNum + idx,
      checked: !!checkedObj.value[obj.caption_contents_seq],
      ...obj,
      brcast_dt: moment(obj.brcast_dt).format('YYYY-MM-DD'),
    }));
  });

  const checkedAll = computed(({
    get() {
      if (gridItems.value?.length === 0) return false;
      return !gridItems.value.find((obj) => !obj.checked);
    },
    set(value) {
      dataItems.value.forEach((obj) => {
        checkedObj.value[obj.caption_contents_seq] = value;
      });
    },
  }));

  const getList = () => window.$store.dispatch(`${STORE_UNMAPPED_CAPTION}/getList`);

  return {
    columns,
    checkedObj,
    checkedAll,
    refCurrentPage,
    gridItems,
    totalPage,
    onChangePage(idx) {
      refCurrentPage.value = idx;
      getList();
    },
  };
}
