import '@/../mdb/scss/index.pro.scss';
import '@/scss/common.scss';
import * as MDB5 from 'mdb-vue-ui-kit';

export default {
  install(app) {
    app.component('MDBBtn', MDB5.MDBBtn);
    app.component('MDBIcon', MDB5.MDBIcon);
    app.component('MDBRow', MDB5.MDBRow);
    app.component('MDBCol', MDB5.MDBCol);
    app.component('MDBChart', MDB5.MDBChart);

    app.component('MDBBadge', MDB5.MDBBadge);
    app.component('MDBCheckbox', MDB5.MDBCheckbox);
    app.component('MDBRadio', MDB5.MDBRadio);
    app.component('MDBInput', MDB5.MDBInput);

    app.component('MDBProgressBar', MDB5.MDBProgressBar);
    app.component('MDBProgress', MDB5.MDBProgress);

    // modal
    app.component('MDBModal', MDB5.MDBModal);
    app.component('MDBModalHeader', MDB5.MDBModalHeader);

    app.component('MDBModalTitle', MDB5.MDBModalTitle);
    app.component('MDBModalBody', MDB5.MDBModalBody);
    app.component('MDBModalFooter', MDB5.MDBModalFooter);

    app.component('MDBToast', MDB5.MDBToast);
  },
};
