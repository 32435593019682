import { computed } from 'vue';

export default function usedSyncStatusChart() {
  const STORE_NAME = 'SyncStatus';
  const chartData = computed(() => window.$store.getters[`${STORE_NAME}/chartData`] || []);

  const CHART_COLORS = [
    'rgba(63, 81, 181, 0.5)',
    'rgba(77, 182, 172, 0.5)',
    'rgba(66, 133, 244, 0.5)',
    'rgba(156, 39, 176, 0.5)',
    'rgba(233, 30, 99, 0.5)',
  ];

  const chartWaits = computed(() => {
    const count = chartData.value.syncWaits?.reduce((acc, obj) => acc + obj.counts, 0);
    return {
      labels: chartData.value.syncWaits?.map((obj) => {
        const { sync_status_nm: title, counts: value } = obj;
        const rate = ((value / count) * 100).toFixed();
        return `${title}: ${value}(${rate}%)`;
      }),
      datasets: [
        {
          data: chartData.value.syncWaits?.map((obj) => obj.counts),
          backgroundColor: CHART_COLORS,
        },
      ],
      datalabels: chartData.value.syncWaits?.map((obj) => obj.sync_status_nm),
    };
  });

  const chartStatus = computed(() => {
    const count = chartData.value.syncStatus?.reduce((acc, obj) => acc + obj.counts, 0);
    return {
      labels: chartData.value.syncStatus?.map((obj) => {
        const { sync_status_nm: title, counts: value } = obj;
        const rate = ((value / count) * 100).toFixed();
        return `${title}: ${value}(${rate}%)`;
      }),
      datasets: [
        {
          data: chartData.value.syncStatus?.map((obj) => obj.counts),
          backgroundColor: CHART_COLORS,
        },
      ],
      datalabels: chartData.value.syncStatus?.map((obj) => obj.sync_status_nm),
    };
  });

  return {
    chartWaits,
    chartStatus,
  };
}
