<template>
  <div class="article-chanel">
    <div class="chanel-info-control">
      <CommonSelect
        v-model:options="refGroups"
        v-model:selected="refSelectedGroup"
        label="그룹"
        @change="onChangeGroup"
        class="selectGroup"
        :preselect="false"
      />
      <div class="buttonGroup">
        <CommonButton
          class="btnSearch"
          @click="onClickAddChannel"
        >
          등록
        </CommonButton>
        <CommonButton
          class="btnSearch"
          @click="onClickModifyChannel"
          :disabled="!selected"
        >
          수정
        </CommonButton>
      </div>
    </div>

    <CommonGrid
      class-name="chanel-info"
      :columns="columns"
      :datas="gridItems"
      @sort="onSort"
      :page-able="false"
    >
      <template
        #checked="{svc_id}"
      >
        <MDBRadio
          :form-check="true"
          class="accroCheck"
          :value="svc_id"
          v-model="selected"
        />
      </template>
    </CommonGrid>
    <Teleport to="#modal">
      <ModalAddChannel
        v-model="modalShow"
        :change-target="changeTarget"
        :groups="refGroups"
      />
    </Teleport>
  </div>
</template>

<script setup>
import CommonGrid from '@/components/CommonGrid.vue';
import useSetupChannelGrid from '@/views/SetupChannel/setupChannelGrid';
import CommonButton from '@/components/CommonButton.vue';
import { ref } from 'vue';
import ModalAddChannel from '@/views/Modal/ModalAddChannel.vue';
import CommonSelect from '@/components/CommonSelect.vue';

const modalShow = ref(false);

// const emit = defineEmits(['selection-changed']);
const selected = ref(null);
const changeTarget = ref();
/* watch(selected, (newValue) => {
  emit('selection-changed', newValue);
}); */

const { columns,
  gridItems,
  refGroups,
  refSelectedGroup,
  requestChangeGroup,
  onSort } = useSetupChannelGrid();

function onClickAddChannel() {
  changeTarget.value = null;
  modalShow.value = true;
}

function onClickModifyChannel() {
  changeTarget.value = gridItems.value.find((channel) => channel.svc_id === selected.value);
  modalShow.value = true;
}

// TODO 최초 변경시에 트리거 발생 안 함.
function onChangeGroup() {
  selected.value = null;
  requestChangeGroup();
}

</script>

<style lang="scss">
.article-chanel{
  .chanel-info-control{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    .selectGroup{
      min-width: 200px;
      max-width: 10%;
    }
    .buttonGroup{
      .btnSearch{
        background-color: #232f3e;
        color: white;
        padding: 0.525rem 1.5rem 0.4rem 1.5rem;
        font-size: 14px;
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }
  .chanel-info{
    .accoItem{
      // justify-content: space-between;
      .acco-select,
      .form-check{
        flex: 0 0 50px;
        display: flex;
        justify-content: center;
      }
      .acco-select{
        justify-content: end;
      }
      .acco-number{
        flex: 0 0 50px;
        display: flex;
        justify-content: center;
      }
      .acco-chanelname,
      .acco-ip,
      .acco-serverid,
      .acco-status{
        flex: 1 0 20%;
      }
    }
  }
}
</style>
