<template>
  <div
    :class="className"
    class="accoWrap"
  >
    <div class="accoItem accoHeader">
      <template
        v-for="(item, idx) in columns"
        :key="idx"
      >
        <slot
          v-if="$slots[`header_${item.field}`]"
          :name="`header_${item.field}`"
        />
        <div
          v-else
          @click="onClick($event, item, idx)"
          @keypress="() => {}"
          :class="['tit', item.headerClassName || '']"
          :style="[item.headerStyle, item.sortable !== false && 'cursor:pointer',
                   clicked === idx && 'color: deepskyblue' ]"
        >
          {{ item.title }}
          <MDBIcon
            v-if="clicked === idx"
            fw
            style="color: deepskyblue"
            :icon="asc ? 'arrow-down' : 'arrow-up'"
            size="sm"
          />
        </div>
      </template>
    </div>
    <template v-if="existData">
      <div
        class="accoItem cell"
        v-for="(dataItem, dataIdx) in datas"
        :key="dataIdx"
        @dblclick="$emit('rowDblClick',$event, dataItem, dataIdx)"
        @click="$emit('rowClick',$event, dataItem, dataIdx)"
        @keyDown="{}"
      >
        <template
          v-for="(columnItem, columnIdx) in columns"
          :key="columnIdx"
        >
          <slot
            v-if="$slots[columnItem.field]"
            :name="columnItem.field"
            v-bind="dataItem"
          />
          <div
            v-else
            :class="[columnItem.className]"
            :style="[columnItem.tdStyle]"
          >
            {{ dataItem[columnItem.field] }}
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="accoItem place-content-center">
        내용 없음
      </div>
    </template>
  </div>
  <MDBPagination
    v-if="pageAble"
    class="justify-content-center mt-3"
  >
    <MDBPageNav
      v-show="pagePrev"
      @click="$emit('changePage', pageArr[0].idx - 1)"
      prev
      icon
      disabled
    />
    <MDBPageItem
      v-for="(item) in pageArr"
      :key="item.idx"
      :active="item.idx === pageIdx "
      @click="$emit('changePage', item.idx)"
      class="ps-1 pe-1"
    >
      {{ item.idx }}
    </MDBPageItem>
    <MDBPageNav
      v-show="pageNext"
      @click="$emit('changePage',pageArr?.slice(-1)[0].idx+1)"
      next
      icon
      disabled
    />
  </MDBPagination>
</template>

<script>
import {
  MDBPagination,
  MDBPageNav,
  MDBPageItem,
} from 'mdb-vue-ui-kit';

import { ref, computed } from 'vue';

export default {
  name: 'CommonGrid',
  components: {
    MDBPagination,
    MDBPageNav,
    MDBPageItem,
  },
  props: {
    className: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    datas: {
      type: Array,
      default() {
        return [];
      },
    },
    pageAble: {
      type: Boolean,
      default: false,
    },
    pageIdx: {
      type: Number,
      default: 0,
    },

    totalPage: {
      type: Number,
      default: 0,
    },

  },
  emits: [
    'sort',
    'changePage',
    'rowDblClick',
    'rowClick',
  ],
  setup(props) {
    const clicked = ref(-1);
    const existData = computed(() => props.datas.length > 0);
    const asc = ref(false);

    const pageArr = computed(() => {
      const startPage = props.pageIdx <= 10 ? 1 : Math.trunc((props.pageIdx - 1) / 10) * 10 + 1;
      const length = Math.min(10, props.totalPage - startPage + 1);
      return Array.from({
        length,
      }, (obj, index) => ({
        idx: startPage + index,
      }));
    });

    const pageNext = computed(() => {
      const lastPage = pageArr.value.slice(-1)[0]?.idx;
      return lastPage > 9 && lastPage < props.totalPage;
    });
    const pagePrev = computed(() => pageArr.value?.length && pageArr.value[0].idx > 1);

    return {
      clicked,
      asc,
      existData,
      pageArr,
      pageNext,
      pagePrev,
    };
  },

  methods: {
    onClick(event, item, idx) {
      if (item.sortable === false) return;

      if (idx !== this.clicked) {
        this.asc = false;
      } else {
        this.asc = !this.asc;
      }

      this.clicked = idx;
      this.$emit('sort', {
        field: item.field,
        asc: this.asc,
      });
    },
  },

};
</script>

<style lang="scss" scoped>
li.page-item {
  cursor: pointer;
}
.place-content-center {
  place-content: center !important;
}
.accoItem{
  &.accoHeader{
    .tit{
      text-align: center;
      &.acco-select{
        flex: 0 0 50px;
      }
    }
  }
  .accoTime{
    &:nth-child(2){
      flex: 0 1 80px;
      justify-content: center;
    }
    &:nth-child(3){
      flex: 0 0 100px;
    }
    &:nth-child(4){
      flex: 1 0 130px;
    }
  }
}
</style>
