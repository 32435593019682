<template>
  <CommonGrid
    :columns="columns"
    :datas="gridItems"
    :page-able="true"
    :page-idx="refCurrentPage"
    :total-page="totalPage"
    @change-page="onChangePage"
  >
    <template
      #checked="{caption_contents_seq}"
    >
      <div class="form-check-wrap">
        <MDBRadio
          :form-check="true"
          class="accroCheck"
          :value="caption_contents_seq"
          v-model="selectedRef"
        />
      </div>
    </template>
  </CommonGrid>
</template>

<script setup>
import CommonGrid from '@/components/CommonGrid.vue';

import { ref, watch } from 'vue';
import useMappingCaptionGrid from './mappingCaptionGrid';

const emit = defineEmits(['selection-changed']);
const props = defineProps({
  selected: {
    type: String,
    default: '',
  },
});

const selectedRef = ref(props.selected);

watch(selectedRef, (newValue) => {
  emit('selection-changed', newValue);
});

watch(props, (newValue) => {
  if (newValue.selected === null) {
    selectedRef.value = null;
  }
});

const { columns,
  refCurrentPage,
  gridItems,
  totalPage,
  onChangePage } = useMappingCaptionGrid();
</script>
<style lang="scss">
.form-check-wrap{
  flex: 0 0 50px;
}
</style>
