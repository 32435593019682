import { ref, computed } from 'vue';
import useAuthenticator from '@/composables/useAuthenticator';

export default function useHeader() {
  const routes = ref(window.$router.options.routes);
  const { /* userGrade, */username, email } = useAuthenticator();
  /*
  const menuList = computed(() => routes.value.filter((obj) => obj.meta?.menu
  && obj.meta.gradeMin <= userGrade.value));
  const menuList2 = computed(() => routes.value.filter((obj) => obj.meta?.setting
  && obj.meta.gradeMin <= userGrade.value));
*/
  const menuList = computed(() => routes.value.filter((obj) => obj.meta?.menu));
  const menuList2 = computed(() => routes.value.filter((obj) => obj.meta?.setting));

  return {
    menuList,
    menuList2,
    username,
    email,
  };
}
