<template>
  <CommonGrid
    class-name="inspection"
    :columns="columns"
    :datas="gridItems"
    @sort="onSort"
    :page-able="true"
    :page-idx="refCurrentPage"
    :total-page="totalPage"
    @change-page="onChangePage"
  >
    <template #sync_status="{ sync_status, sync_server_result}">
      <div
        class="acco-status"
        :class="{
          'text-danger' : sync_server_result === '실패' ,
          'col-waiting': sync_status === '검수대기'}"
      >
        {{ sync_status }}
      </div>
    </template>
  </CommonGrid>
</template>

<script setup>
import CommonGrid from '@/components/CommonGrid.vue';
// import CommonButton from '@/components/CommonButton.vue';
import useCaptionValidationGrid from './captionValidationGrid';

const { columns,
  refCurrentPage,
  gridItems,
  totalPage,
  onSort,
  onChangePage } = useCaptionValidationGrid();

</script>
<style lang="scss">
.inspection{
  .accoItem{
    min-width: 1780px;
    .acco-check{
      flex: 0 0 50px;
    }
    .acco-channel{
      flex: 0 0 120px;
    }
    .acco-Date,
    .acco-status,
    .acco-manager{
      flex: 0 0 100px;
    }
    .acco-episode{
      flex: 0 0 200px;
      word-break: break-all;
    }
    .acco-filename,
    .acco-captionname,
    .acco-caption-url{
      flex: 1 0 200px;
      text-align: left;
      word-break: break-all;
    }
  }
}
</style>
