/* eslint-disable no-underscore-dangle *//* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import axios from 'axios';
import useAuthenticator from '@/composables/useAuthenticator';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const _config = {
  baseURL: process.env.VUE_APP_API_BASE_URL || '',
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  // paramsSerializer: (param) => qs.stringify(param),
};

const _axios = axios.create(_config);

_axios.interceptors.request.use(
  (config) => {
    window.$util.Loading(true);
    if (process.env.VUE_APP_USE_AXIOS_LOG === 'true') console.log('_axios.interceptors.request', config);
    // const { jwtToken } = useAuthenticator();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = window.localStorage.jwtToken || '';
    return config; // Do something before request is sent
  },
  (error) => {
    window.$util.Loading(false);
    return Promise.reject(error); // Do something with request error
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    window.$util.Loading(false);
    // Do something with response data
    if (process.env.VUE_APP_USE_AXIOS_LOG === 'true') console.log('_axios.interceptors.response', response);
    if (response.status === 200 && response.data) {
      if (response.config?.responseType === 'blob') return response;
      return response.data;
    } if (response.data && response.data.class) {
      return response.data;
    }

    // window.NotificationError(response.data.resultMessage);
    // throw Error(response.data.resultMessage);
    return Promise.reject(response);
  },
  (error) => {
    window.$util.Loading(false);
    if (error.response.status === 401) {
      window.$router.push('Logout');
    }
    return Promise.reject(error); // Do something with response error
  },
);

// eslint-disable-next-line no-unused-vars, func-names
Plugin.install = function (app) {
  window.$axios = _axios;
  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$axios = _axios;
};

export default Plugin;
