<template>
  <div class="gridControlWrap">
    <div class="searchWrap">
      <CommonDatepicker
        v-model="date"
      />
      <div class="searchBox">
        <CommonSelect
          v-model:options="RESULT_TYPE"
          v-model:selected="refResultType"
          placeholder="결과 선택"
        />
        <CommonInput
          label="채널명"
          v-model="refChannel"
          @keyup.enter="getList()"
        />
        <CommonButton
          class="btnSearch"
          @click="getList()"
        >
          검색
        </CommonButton>
        <CommonButton
          class="btnSearch btn-tool"
          @click="requestDownload()"
          icon="file-archive"
          icon-style="fas"
        >
          검수툴 다운로드
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import CommonDatepicker from '@/components/CommonDatepicker.vue';
import CommonButton from '@/components/CommonButton.vue';
import CommonSelect from '@/components/CommonSelect.vue';
import CommonInput from '@/components/CommonInput.vue';
import useCaptionValidationSearch from '@/views/CaptionValidation/captionValidationSearch';
import { ref } from 'vue';

const RESULT_TYPE = ref([
  {
    text: '처리상태', value: undefined,
  },
  {
    text: '검수대기', value: 'SYU003',
  },
  {
    text: '검수진행중', value: 'SYU004',
  },
  {
    text: '자막완료', value: 'SYU005',
  },
]);
const {
  date,
  refResultType,
  getList,
  requestDownload,
  refChannel,
} = useCaptionValidationSearch();

</script>
<style lang="scss">
.searchWrap {
  .dp__input_wrap{
    min-width: 230px;
  }
}
.btn-group{
  .btn{
    min-width: 72px;
  }
}
.input-caption{
  min-width: 100px;
}
.btnSearch{
  min-width: 75px;
}
.gridControlWrap .searchWrap .searchBox .btnSearch.btn-tool{
  min-width: 180px;
  background-color: #ffa900;
}

</style>
