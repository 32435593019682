<template>
  <section
    v-if="existCognitoGroups"
    style="position: relative; overflow-y: hidden; min-height: 100vh"
    class="d-flex w-100"
  >
    <HeaderSideNav />
    <HeaderSideHeader>
      <slot />
    </HeaderSideHeader>
  </section>
</template>

<script setup>
import useAuthenticator from '@/composables/useAuthenticator';
import HeaderSideNav from './HeaderSideNav.vue';
import HeaderSideHeader from './HeaderSideHeader.vue';

const { existCognitoGroups } = useAuthenticator();

</script>

<style>
header {
  color: #3f4254;
}
</style>
