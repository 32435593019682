export default {
  state: {
    url: '',
    dataItems: [],
    search: {},
  },
  mutations: {
    search(state, item) {
      state.search = {
        ...state.search,
        ...item,
      };
    },
    dataItems(state, item) {
      state.dataItems = item;
    },
    setState(state, item) {
      Object.keys(item)
        .forEach((key) => {
          state[key] = item[key];
        });
    },
  },
  actions: {
    async getList({ commit, dispatch, state }, param) {
      const { url, search } = state;
      const params = {
        ...param,
      };

      Object.keys(search)
        .forEach((key) => {
          if (search[key] !== undefined) params[key] = search[key];
        });

      try {
        const res = await window.$axios.get(url, {
          params,
        });
        dispatch('setListItems', res);
      } catch (e) {
        // windows.alert(~~~)
        commit('setState', {
          dataItems: [],
        });
      }
    },

    resetSearchItem({ commit, state }) {
      const { search } = state;
      const params = {};
      Object.keys(search)
        .forEach((key) => {
          params[key] = null;
        });
      commit('search', params);
    },
    setListItems({ commit }, params) {
      commit('dataItems', params);
    },
    setSearchItem({ commit }, params) {
      commit('search', params);
    },
    setChartData({ commit }, params) {
      commit('chartData', params);
    },
  },
  getters: {
    search: (state) => state.search,
    dataItems: ({ dataItems }) => dataItems,
  },
};
