import moment from 'moment';
import SyncStatus from './SyncStatus';
import searchMixin from './searchMixin';

export const STORE_CAPTION_VALIDATION = 'CaptionValidation';
export default {
  ...SyncStatus,
  state: {
    ...SyncStatus.state,
    url: 'validations/lists',
    search: {
      limit: 10,
      offset_page: 1,
      brcast_start_dt: moment()
        .subtract(1, 'months')
        .startOf('day')
        .format('YYYYMMDD'),
      brcast_end_dt: moment()
        .endOf('day')
        .format('YYYYMMDD'),
      result_type: undefined,
    },
  },
  actions: {
    ...SyncStatus.actions,
    setListItems({ commit }, apiResult) {
      const { captions, pagination } = apiResult;
      commit('dataItems', captions || []);
      commit('setState', {
        pagination,
      });
    },
    async getList(ctx, param) {
      await searchMixin.actions.getList(ctx, param);
    },
    beforeRouteEnter() {
    },

  },
};
