<template>
  <MDBFooter>
    <div
      :class="className"
      style="background-color: rgba(0, 0, 0, 0.2)"
    >
      <div class="text-muted font-weight-bold text-center p-3">
        © {{ year }} Copyright: SK Broadband
      </div>
      <div class="text-muted font-weight-bold">
        Ver. {{ version }} {{ nodeEnv }}
      </div>
    </div>
  </MDBFooter>
</template>

<script>
import { MDBFooter } from 'mdb-vue-ui-kit';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  components: {
    MDBFooter,
  },
  data() {
    return {
      className: [
        'container-fluid',
        'd-flex',
        'flex-column',
        'flex-md-row',
        'align-items-center',
        'justify-content-between',
      ],
    };
  },
  computed: {
    year() {
      return process.env.VUE_APP_YEAR || '2022';
    },
    version() {
      return process.env.VUE_APP_VERSION || '20220701.1';
    },
    nodeEnv() {
      return process.env.NODE_ENV;
    },
  },
};

</script>
