import { ref, computed } from 'vue';
import moment from 'moment';

export const STORE_SYNC_SEARCH = 'SyncSearch';

export default function useEncoderHistorySearch() {
  const searchFromDate = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'brcast_start_dt');
  const searchToDate = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'brcast_end_dt');

  const searchContentName = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'search_value');
  const searchResultType = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'result_type');
  const searchSearchType = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'search_type');

  const searchChannel = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'channel_nm');

  const refCurrentPage = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'offset_page');

  const refContentName = ref(searchContentName);
  const refResultType = ref(searchResultType);
  const refSearchType = ref(searchSearchType);
  const refChannel = ref(searchChannel);

  const date = computed({
    get() {
      const searchFromDt = moment(searchFromDate.value);
      const searchToDt = moment(searchToDate.value);
      return [
        searchFromDt.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        searchToDt.hour(23)
          .minute(59)
          .second(0)
          .millisecond(0)
          .toDate(),
      ];
    },
    set(value) {
      const from = value[0]?.valueOf();
      const fromYYYYMMDD = moment(from).format('YYYYMMDD');

      const to = value[1]?.valueOf();
      const toYYYYMMDD = moment(to).format('YYYYMMDD');
      if (fromYYYYMMDD !== searchFromDate.value || toYYYYMMDD !== searchToDate.value) {
        window.$store.commit(`${STORE_SYNC_SEARCH}/search`, {
          brcast_start_dt: fromYYYYMMDD,
          brcast_end_dt: toYYYYMMDD,

        });
      }
    },
  });

  function getList(page) {
    refCurrentPage.value = page || 1;
    window.$store.dispatch(`${STORE_SYNC_SEARCH}/clearCheckedItems`);
    window.$store.dispatch(`${STORE_SYNC_SEARCH}/getList`);
  }

  const requestDownload = async () => {
    const arr = window.$store.getters[`${STORE_SYNC_SEARCH}/checkedItems`];
    if (arr.length === 0) {
      return;
    }

    try {
      const response = await window.$axios.get(
        'synclists/downloads',
        {
          params: {
            job_ids: arr.join(','),
          },
          responseType: 'blob',
        },
      );

      await window.$util.SaveBlob(response);
    } catch (e) {
      window.$util.Toast('다운로드에 실패했습니다.');
      throw new Error('파일 다운로드 실패');
    }
    window.$store.dispatch(`${STORE_SYNC_SEARCH}/clearCheckedItems`);
    window.$store.dispatch(`${STORE_SYNC_SEARCH}/getList`);
  };

  return {
    date,
    refContentName,
    refResultType,
    refSearchType,
    refChannel,
    getList,
    requestDownload,
  };
}
