export default {
  namespaced: true,
  state: {
    visible: false,
    title: '',
    color: '',
    body: '',
  },
  mutations: {
    setState(state, item) {
      Object.keys(item)
        .forEach((key) => {
          state[key] = item[key];
        });
    },
  },
  actions: {
    show({ commit }, obj) {
      commit('setState', {
        visible: true,
        color: 'light',
        ...obj,
        body: JSON.stringify(obj.body, null, 1),
      });
    },
  },
  getters: {
    state: (state) => state,
  },
};
