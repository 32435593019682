<template>
  <div class="searchWrap">
    <Datepicker
      v-show="rangeType === 'day'"
      range
      multi-calendars
      :enable-time-picker="false"
      position="left"
      cancel-text="닫기"
      select-text="확인"
      locale="ko-KR"
      format="yyyy/MM/dd"
      :partial-range="false"
      :clearable="false"
      auto-apply
      v-model="modelDay"
      @update:model-value="onChangeDate"
    />
    <Datepicker
      v-show="rangeType === 'month'"
      :enable-time-picker="false"
      :enable-day-picker="false"
      position="left"
      cancel-text="닫기"
      select-text="확인"
      locale="ko-KR"
      format="yyyy년 MM월"
      :partial-range="false"
      :clearable="false"
      auto-apply
      month-picker
      v-model="modelMonth"
      @update:model-value="onChangeDate"
    />

    <div
      class="btn-group"
      role="group"
      aria-label="Basic example"
    >
      <button
        type="button"
        class="btn btn-primary"
        :class="['btn', 'btn-primary', rangeType === RANGE_TYPE.DAY && 'active']"
        @click="onSelectType(RANGE_TYPE.DAY)"
      >
        기간
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :class="['btn', 'btn-primary', rangeType === RANGE_TYPE.MONTH && 'active']"
        @click="onSelectType(RANGE_TYPE.MONTH)"
      >
        월간
      </button>
    </div>
  </div>
</template>

<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';
import moment from 'moment';

const RANGE_TYPE = {
  DAY: 'day', MONTH: 'month',
};

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Array,
    default() {
      return [];
    },
  },
});

const rangeType = ref('day');
const defaultDay = moment(props.modelValue?.[0] || '');
const modelMonth = ref({
  month: defaultDay.month(), year: defaultDay.year(),
});
const modelDay = ref([...props.modelValue]);

const onChangeDate = (value) => {
  if (value instanceof Array) { // 일간
    emit('update:modelValue', value);
  } else {
    const newDate = moment().year(value.year).month(value.month).date(1);
    emit('update:modelValue', [
      newDate.toDate(),
      newDate.endOf('month').toDate(),
    ]);
  }
};

const onSelectType = (type) => {
  switch (type) {
    case RANGE_TYPE.DAY:
      rangeType.value = RANGE_TYPE.DAY;
      onChangeDate(modelDay.value);
      break;
    case RANGE_TYPE.MONTH:
      rangeType.value = RANGE_TYPE.MONTH;
      onChangeDate(modelMonth.value);
      break;
    default:
      break;
  }
};

</script>

<style scoped lang="scss">
.dp__input {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
