<template>
  <MDBModal
    tabindex="-1"
    :model-value="visible"
    :keyboard="false"
    :focus="false"
    scrollable
    static-backdrop
    size="sm"
  >
    <MDBModalHeader>
      <MDBModalTitle>{{ props.title }}</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <slot />
    </MDBModalBody>
    <MDBModalFooter>
      <slot name="modalFooter" />
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import {
  getCurrentInstance, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';

const internalInstance = getCurrentInstance();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});
const visible = ref(internalInstance.attrs.modelValue);

const closeModal = () => {
  internalInstance.emit('update:modelValue', false);
};

const route = useRoute();
watch(route, () => {
  closeModal();
});
</script>
