<template>
  <LayoutModal
    title="자막동기화 처리 현황"
    size="xl"
    class="exlModal"
  >
    <MDBRow
      class="align-items-center bg-light g-0 row-title "
    >
      <MDBCol>
        에피소드
      </MDBCol>
      <MDBCol>
        시리즈
      </MDBCol>
      <MDBCol>
        JOBID
      </MDBCol>
      <MDBCol>
        처리상태
      </MDBCol>
      <MDBCol>
        채널
      </MDBCol>
      <MDBCol>
        요청일시
      </MDBCol>
    </MDBRow>
    <MDBRow
      class="g-0 row-lists"
    >
      <MDBCol class="row-list-cell">
        {{ caption?.epsd_nm }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ caption?.sris_nm }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ caption?.job_id }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ caption?.sync_status }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ caption?.channel_nm }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ caption?.reg_dt }}
      </MDBCol>
    </MDBRow>
    <div class="content">
      <div class="d-flex title">
        # 자막동기화 처리 상태
        <div class="text-danger px-2">
          {{ errorStatue }}
        </div>
      </div>
      <CommonGrid
        :columns="COLUMN_BATCH"
        :datas="batchJobs"
        :page-able="false"
      />

      <div class="d-flex p-2 title">
        # Agent처리 상태 목록
      </div>
      <CommonGrid
        :columns="COLUMN_SYNC"
        :datas="syncJobs"
        :page-able="false"
      />
    </div>
    <template #modalFooter>
      <CommonButton
        color="btn-outline-dark"
        @click="closeModal"
      >
        확인
      </CommonButton>
    </template>
  </LayoutModal>
</template>

<script setup>

import {
  computed,
  getCurrentInstance,
} from 'vue';
import LayoutModal from '@/components/layout/LayoutModal.vue';
import CommonButton from '@/components/CommonButton.vue';
import CommonGrid from '@/components/CommonGrid.vue';

const internalInstance = getCurrentInstance();

const props = defineProps({
  caption: {
    type: Object,
    default() {
      return {};
    },
  },
});

const COLUMN_BATCH = Object.freeze([
  {
    field: 'depth',
    title: '단계',
    headerClassName: 'accoDeeps',
    className: 'accoDeeps',
    sortable: false,
  },
  {
    field: 'ingestion_name',
    title: '작업명',
    headerClassName: 'accoContents',
    className: 'accoContents',
    sortable: false,
  },
  {
    field: 'start_time',
    title: '시작시간',
    headerClassName: 'accoEtc',
    className: 'accoEtc',
    sortable: false,
  },
  {
    field: 'end_time',
    title: '완료시간',
    headerClassName: 'accoEtc',
    className: 'accoEtc',
    sortable: false,
  },
]);
const COLUMN_SYNC = Object.freeze([

  {
    field: 'sync_result_type',
    title: '작업명',
    headerClassName: 'accoContents',
    className: 'accoContents',
    sortable: false,
  },
  {
    field: 'upd_dt',
    title: '시간',
    headerClassName: 'accoContents',
    className: 'accoContents',
    sortable: false,
  },

]);

const caption = computed(() => (props?.caption));
const syncJobs = computed(() => (props?.caption.syncJobs));
const batchJobs = computed(() => (props?.caption.batchJobs));
const errorStatue = computed(() => (caption.value.sync_server_result === '실패'
  ? `   ✔ERROR - ${caption.value.sync_server_result_msg}` : ''));
function closeModal() {
  internalInstance.emit('update:modelValue', false);
}
</script>
<style lang="scss" scoped>

.row-title{
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .col{
    padding: 0.5rem 0;
    &:not(:first-child){
      border-left: 1px solid #e0e0e0;
    }
  }
}
.row-lists{
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  word-break: break-all;
  &:nth-last-child(2){
    border-bottom: 1px solid #e0e0e0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .row-list-cell{
    padding: 0.5rem 0;
    &:not(:first-child){
      border-left: 1px solid #e0e0e0;
    }
  }

}
.content{
  min-width: initial;
  margin-top: 1rem;
  .title{
    font-weight: bold;
    margin-top: 1rem;
  }
}
</style>
