<template>
  <MDBAlert
    v-model="visible"
    :color="color"
    position="top-right"
    stacking
    width="535px"
    append-to-body
    autohide
    :delay="5000"
  >
    {{ body }}
  </MDBAlert>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';
import { MDBAlert } from 'mdb-vue-ui-kit';

const visible = computed(({
  get() {
    return window.$store.state.Alert.visible;
  },
  set(value) {
    window.$store.commit('Alert/setState', {
      visible: value,
    });
  },
}));

const timeoutValue = ref(null);

const body = computed(() => window.$store.state.Alert.body);
const color = computed(() => window.$store.state.Alert.color);

// eslint-disable-next-line no-underscore-dangle
const _clearTimeout = () => {
  clearTimeout(timeoutValue.value);
  timeoutValue.value = null;
};

const hide = () => {
  if (!timeoutValue.value) return;
  visible.value = false;
};

watch(visible, (newVal) => {
  if (newVal) {
    _clearTimeout();
    timeoutValue.value = setTimeout(hide, 3000);
  } else {
    _clearTimeout();
  }
});

</script>
