<template>
  <ResultSummary />
  <LayoutBase>
    <template #search>
      <Search />
    </template>
    <template #searchGrid>
      <SearchGrid />
    </template>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/components/layout/LayoutBase.vue';

import ResultSummary from './SyncSearch/SyncSearchSummary.vue';
import Search from './SyncSearch/SyncSearchSearch.vue';
import SearchGrid from './SyncSearch/SyncSearchGrid.vue';

export default {
  components: {
    LayoutBase,
    Search,
    SearchGrid,
    ResultSummary,
  },
  async beforeRouteEnter(to, from, next) {
    window.$store.dispatch('SyncSearch/beforeRouteEnter');
    await window.$store.dispatch('SyncSearch/getList');
    next();
  },
};
</script>
