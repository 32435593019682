<template>
  <div style="padding: var(--amplify-space-large); text-align: center">
    <p
      class="amplify-text"
      style="color: var(--amplify-colors-neutral-80)"
    >
      © All Rights Reserved ::
    </p>
  </div>
</template>
