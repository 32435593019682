<template>
  <MDBAccordion
    v-model="activeItem"
    borderless
  >
    <MDBAccordionItem
      header-title="자막동기화 현황"
      collapse-id="collapseOne"
      class="my-2"
    >
      <div class="container-chart">
        <CommonChartDoughnut
          title="CC자막 동기화 현황"
          :data="chartStatus"
          class="chart1"
        />
        <CommonChartDoughnut
          title=" 작업대기"
          :data="chartWaits"
          class="chart2"
        />
      </div>
    </MDBAccordionItem>
  </MDBAccordion>
</template>

<script setup>
import { MDBAccordion, MDBAccordionItem } from 'mdb-vue-ui-kit';
import CommonChartDoughnut from '@/components/CommonChartDoughnut.vue';
import usedSyncStatusChart from '@/views/SyncStatus/syncStatusChart';
import { ref } from 'vue';

const activeItem = ref('collapseOne');
const {
  chartWaits,
  chartStatus,
} = usedSyncStatusChart();
</script>

<style lang="scss" scoped>
.accordion-item{
  overflow: hidden;

}
.container-chart {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  // .chart1,
  // .chart2 {
  //   margin: 0.5rem 0;
  //   padding: 0.5rem 2rem;
  //   height: initial !important;
  //   max-width: 100%;
  // }
}
</style>
