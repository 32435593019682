<template>
  <canvas
    ref="canvasRef"
    :class="canvasClass"
  />
</template>

<script setup>
import {
  onMounted, ref, watch,
} from 'vue';
import generateCharts from './generateCharts';

const DEFINE_TYPE = 'doughnut';

const props = defineProps({
  canvasClass: {
    type: String,
    default: '',
  },
  data: {
    type: Object,
    default() {
      return {};
    },
  },
  options: {
    type: Object,
    default() {
      return {};
    },
  },
  plugins: {
    type: Object,
    default() {
      return {};
    },
  },
});

const canvasRef = ref(null);

const renderChartFunc = ref(null);
const updateChartFunc = ref(null);

const getChartsFunctions = ({
  renderChart,
  updateChart,
}) => {
  renderChartFunc.value = renderChart;
  updateChartFunc.value = updateChart;
};

watch(
  () => props.data,
  (newData) => {
    updateChartFunc.value(
      newData,
      props.options,
      props.plugins,
    );
  },
);

watch(
  () => props.options,
  (newOptions) => {
    updateChartFunc.value(props.data, newOptions, props.plugins);
  },
);

onMounted(() => {
  // chart functions must be initialized on mount to properly pass canvasRef value to Charts.js
  const chartsFunctions = generateCharts(
    canvasRef.value,
    DEFINE_TYPE,
    props.data,
    props.options,
    props.plugins,
  );

  getChartsFunctions(chartsFunctions);

  renderChartFunc.value();
});
</script>
