<template>
  <LayoutBase>
    <template #search>
      <Search />
    </template>
    <template #searchGrid>
      <SearchGrid />
    </template>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/components/layout/LayoutBase.vue';

import { STORE_UNMAPPED_CAPTION } from '@/store/modules/UnmappedCaption';
import Search from './UnmappedCaption/UnmappedCaptionSearch.vue';
import SearchGrid from './UnmappedCaption/UnmappedCaptionGrid.vue';

export default {
  components: {
    LayoutBase,
    Search,
    SearchGrid,
  },
  async beforeRouteEnter(to, from, next) {
    window.$store.dispatch(`${STORE_UNMAPPED_CAPTION}/beforeRouteEnter`);
    await window.$store.dispatch(`${STORE_UNMAPPED_CAPTION}/getList`);
    next();
  },
};
</script>
