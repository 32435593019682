// import { ref } from 'vue';
// import moment from 'moment';
import searchMixin from './searchMixin';

export const STORE_SYNC_STATUS = 'SyncStatus';
export default {
  namespaced: true,
  state: {
    ...searchMixin.state,
    url: 'synctoday/lists',
    urlChart: 'synctoday/status',
    lastUpdateTime: '',
    totalCount: 0,
    pagination: {
      limit: 1000,
      offset_page: 1,
      total_cnt: 0,
      total_page_num: 0,
    },
    result: {},
    syncStatus: {},
    syncWaits: {},
    search: {
      limit: 1000,
      offset_page: 1,
    },
    grid: {
      checked: {},
    },

    chartData: {},
  },
  mutations: {
    ...searchMixin.mutations,
    chartData(state, data) {
      state.chartData = data;
    },
  },
  actions: {
    ...searchMixin.actions,
    setListItems({ commit }, apiResult) {
      const { captions, lastUpdateTime = new Date().toLocaleString(), pagination } = apiResult;
      commit('dataItems', captions || []);
      commit('setState', {
        lastUpdateTime,
        totalCount: pagination.total_cnt,
      });
    },
    setChartData({ commit }, apiResult) {
      const { syncStatus, syncWaits } = apiResult;

      commit('chartData', {
        syncStatus, syncWaits,
      } || { });
    },
    async getList(ctx, param) {
      searchMixin.actions.getList(ctx, param);

      // NOTE 반복호출 필요한지 확인 필요
      /*
     await searchMixin.actions.getList(ctx, param);

     if (timerId.value) {
       clearTimeout(timerId.value);
       timerId.value = null;
     }
     timerId.value = setInterval(() => {
       ctx.dispatch('getList');
     }, 60000);

      */
    },
    /*     async getChart(ctx, param) {
      searchMixin.actions.getChart(ctx, param);
    }, */
    async getChart({ commit, dispatch, state }, param) {
      // eslint-disable-next-line camelcase
      const { urlChart } = state;
      const params = {
        ...param,
      };

      try {
        const res = await window.$axios.get(urlChart, {
          params,
        });
        dispatch('setChartData', res);
      } catch (e) {
        // windows.alert(~~~)
        commit('setState', {
          chartData: [],
        });
      }
    },
    clearCheckedItems({ commit }) {
      commit('setState', {
        grid: {
          checked: {},
        },
      });
    },
    beforeRouteEnter({ commit, dispatch }) {
      commit('setState', {
        search: {
          limit: 1000,
          offset_page: 1,
        },
      });
      dispatch('clearCheckedItems');
    },
    beforeRouteLeave() {
    /*   if (timerId.value) {
        clearTimeout(timerId.value);
        timerId.value = null;
      } */
    },
  },
  getters: {
    ...searchMixin.getters,
    lastUpdateTime: ({ lastUpdateTime }) => lastUpdateTime,
    totalCount: ({ totalCount }) => totalCount,
    checkedItems: ({ grid }) => {
      const { checked } = grid;
      const params = [];
      Object.keys(checked)
        .forEach((key) => {
          if (checked[key]) {
            params.push(key);
          }
        });
      return params;
    },
    chartData: ({ chartData }) => chartData,
  },
};
