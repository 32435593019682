import { ref, computed } from 'vue';
import { STORE_SYNC_STATUS } from '@/store/modules/SyncStatus';

export default function useEncoderJobSearchGrid() {
  const columns = Object.freeze([
    /* {
      field: 'checked',
    }, */
    {
      field: 'epsd_nm',
      title: '에피소드',
      headerClassName: 'acco-episode',
      className: 'acco-episode',
    },
    {
      field: 'caption_file_nm',
      title: '자막파일명',
      headerClassName: 'acco-captionname',
      className: 'acco-captionname',
    },
    {
      field: 'brcast_type',
      title: '분류',
      headerClassName: 'acco-type',
      className: 'acco-type',
      sortable: false,
    },
    {
      field: 'sync_status',
      title: '상태',
      headerClassName: 'acco-status',
      className: 'acco-status',
    },
    {
      field: 'schedule_stop',
      title: '중지',
      headerClassName: 'acco-stop',
      className: 'acco-stop',
    },
    /*  {
      field: 'progress',
      title: '진행',
      headerClassName: 'acco-process',
      className: 'acco-process',
    }, */
  ]);

  const refCurrentPage = window.$util.computeGetSet(STORE_SYNC_STATUS, 'search', 'offset_page');
  const checkedObj = window.$util.computeGetSet(STORE_SYNC_STATUS, 'grid', 'checked');

  const dataItems = computed(() => window.$store.getters[`${STORE_SYNC_STATUS}/dataItems`] || []);
  const chartData = computed(() => window.$store.getters[`${STORE_SYNC_STATUS}/chartData`] || []);
  const gridItems = computed(() => dataItems.value.map((obj) => ({
    checked: !!checkedObj.value[obj.job_id],
    ...obj,
  })));

  const checkedAll = computed(({
    get() {
      if (gridItems.value?.length === 0) return false;
      return !gridItems.value.find((obj) => !obj.checked);
    },
    set(value) {
      dataItems.value.forEach((obj) => {
        checkedObj.value[obj.job_id] = value;
      });
    },
  }));

  async function requestChangeSyncSchedule(job_id) {
    if (!job_id) {
      return;
    }
    try {
      const res = await window.$axios.patch(`synctoday/actions?job_id=${job_id}`);

      const { code, message } = await res.result;
      if (code === 200) {
        window.$util.Toast('동기화가 중지되었습니다.');
      } else {
        window.$util.Error(`요청에 실패했습니다. ${message}`);
      }
    } catch (e) {
      window.$util.Error('요청에 실패했습니다.');
      throw new Error('동기화 중지 실패');
    }

    // window.$store.dispatch(`${STORE_SYNC_STATUS}/clearCheckedItems`);
    window.$store.dispatch(`${STORE_SYNC_STATUS}/getList`);
  }

  const getList = () => window.$store.dispatch(`${STORE_SYNC_STATUS}/getList`);

  const sort = ref(null);
  return {
    columns,
    checkedObj,
    refCurrentPage,
    gridItems,
    chartData,
    checkedAll,
    requestChangeSyncSchedule,
    onSort(obj) {
      sort.value = obj;
    },
    onChangePage(idx) {
      refCurrentPage.value = idx;
      getList();
    },
  };
}
