import { computed } from 'vue';

import { useAuthenticator as useAwsAuthenticator } from '@aws-amplify/ui-vue';

const DEFINE_USER_GRADE_ADMIN = 10;
const DEFINE_USER_GRADE_OPERATOR = 5;
const DEFINE_USER_GRADE_NONE = 0;

function useUserGrade(user) {
  const groups = computed(() => {
    const cognitoGroups = user?.signInUserSession?.idToken?.payload?.['cognito:groups'];
    return cognitoGroups || [];
  });

  const userGrade = computed(() => {
    if (groups?.value?.includes('admin')) return DEFINE_USER_GRADE_ADMIN;
    if (groups?.value?.includes('operator')) return DEFINE_USER_GRADE_OPERATOR;
    return DEFINE_USER_GRADE_NONE;
  });
  /*
  const existCognitoGroups = computed(() => {
    if (groups.value?.length > 0) {
      return true;
    }
    return false;
  }); */

  return {
    groups,
    existCognitoGroups: true,
    userGrade,
  };
}

export default function useAuthenticator() {
  const auth = useAwsAuthenticator();
  const authRoute = computed(() => auth.route);
  const authStatus = computed(() => auth.authStatus);
  const isPending = computed(() => auth.isPending);

  const {
    groups,
    existCognitoGroups,
    userGrade,
  } = useUserGrade(auth.user);

  return {
    auth,
    authRoute,
    authStatus,
    isPending,
    groups,
    existCognitoGroups,

    userGrade,
    username: auth?.user?.username || '',
    email: auth?.user?.attributes?.email || '',
    jwtToken: auth?.user?.signInUserSession?.accessToken?.jwtToken || '',
  };
}

export {
  DEFINE_USER_GRADE_ADMIN,
  DEFINE_USER_GRADE_OPERATOR,
  DEFINE_USER_GRADE_NONE,
  useUserGrade,
};
