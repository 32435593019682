<template>
  <LayoutModal
    title="자막 매핑"
    size="xl"
    class="exlModal"
    :scrollable="false"
  >
    <MDBRow
      class="align-items-center bg-light g-0 row-title"
    >
      <MDBCol>
        에피소드
      </MDBCol>
      <MDBCol>
        에피소드ID
      </MDBCol>
      <MDBCol>
        시리즈
      </MDBCol>
      <MDBCol>
        시리즈ID
      </MDBCol>
      <MDBCol>
        회차
      </MDBCol>
    </MDBRow>
    <MDBRow
      class="g-0 row-lists"
    >
      <MDBCol class="row-list-cell">
        {{ targetProgram?.epsd_nm }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ targetProgram?.epsd_id }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ targetProgram?.sris_nm }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ targetProgram?.sris_id }}
      </MDBCol>
      <MDBCol class="row-list-cell">
        {{ targetProgram?.brcast_rseq }}
      </MDBCol>
    </MDBRow>

    <div class="content">
      <MappingCaptionSearch @selection-changed="captionSelected" />
      <MappingCationGrid
        ref="grid"
        @selection-changed="captionSelected"
        :selected="selectedCaptionId"
      />
    </div>
    <template #modalFooter>
      <CommonButton
        color="dark"
        @click="onMappingBtClick"
        :disabled="disableMapping"
      >
        매핑
      </CommonButton>
      <CommonButton
        color="btn-outline-dark"
        @click="goNext"
      >
        {{ titleNextBt }}
      </CommonButton>
    </template>
  </LayoutModal>
</template>

<script setup>

import LayoutModal from '@/components/layout/LayoutModal.vue';
import CommonButton from '@/components/CommonButton.vue';
import {
  useAttrs, computed, watch, ref, getCurrentInstance,
} from 'vue';
import { STORE_RESYNC_SEARCH } from '@/store/modules/ReSyncSearch';
import MappingCationGrid from '../ReSyncSearch/MappngCaptionGrid.vue';
import MappingCaptionSearch from '../ReSyncSearch/MappingCaptionSearch.vue';

const internalInstance = getCurrentInstance();
const attrs = useAttrs();
const disableMapping = ref(true);
const programs = ref([]);
const idxPrg = ref(0);

const visible = computed(() => attrs.modelValue);
const targetProgram = computed(() => programs.value[idxPrg.value] || {});
const titleNextBt = computed(() => (idxPrg.value + 1 >= programs.value.length ? '닫기' : `다음(${idxPrg.value + 1} / ${programs.value.length})`));

const selectedCaptionId = ref();

watch(
  visible,
  (newVal) => {
    if (newVal) {
      idxPrg.value = 0;
      disableMapping.value = true;
      const checkedPrg = window.$store.getters[`${STORE_RESYNC_SEARCH}/checkedItems`];
      const dataItems = window.$store.getters[`${STORE_RESYNC_SEARCH}/dataItems`];
      checkedPrg.forEach((id) => {
        programs.value.push(dataItems.find((e) => e.brcast_contents_seq.toString() === id));
      });
    } else {
      window.$store.dispatch(`${STORE_RESYNC_SEARCH}/clearCheckedItems`);
      window.$store.dispatch(`${STORE_RESYNC_SEARCH}/getList`);
      selectedCaptionId.value = null;

      programs.value = [];
    }
  },
);

function closeModal() {
  internalInstance.emit('update:modelValue', false);
}

function goNext() {
  if (idxPrg.value < programs.value.length - 1) {
    idxPrg.value += 1;
    disableMapping.value = !selectedCaptionId.value;
    selectedCaptionId.value = null;
  } else {
    closeModal();
  }
}

async function requestMapping() {
  try {
    const res = await window.$axios.post('mappings/mapping', {}, {
      params: {
        brcast_contents_seq: targetProgram.value.brcast_contents_seq,
        caption_contents_seq: selectedCaptionId.value,
      },
    });
    const { code } = res.result;
    if (code === 200) {
      window.$util.Toast('매핑을 완료했습니다.');
      await window.$store.dispatch('CaptionSearch/getList');
      goNext();
    }
  } catch (e) {
    window.$util.Error({
      url: 'mapping/mapping',
      e,
    });
  }
}

function onMappingBtClick() {
  if (!selectedCaptionId.value) {
    window.$util.Toast('자막을 선택하세요.');
    return;
  }
  disableMapping.value = true;
  requestMapping();
}

function captionSelected(value) {
  if (value) {
    selectedCaptionId.value = value;
    disableMapping.value = false;
  } else {
    disableMapping.value = true;
    selectedCaptionId.value = null;
  }
}

</script>

<style lang="scss" scoped>
.row-title{
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .col{
    padding: 0.5rem 0;
    &:not(:first-child){
      border-left: 1px solid #e0e0e0;
    }
  }
}
.row-lists{
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  .row-list-cell{
    padding: 0.5rem 0;
    &:not(:first-child){
      border-left: 1px solid #e0e0e0;
    }
  }

  &:nth-last-child(2){
    border-bottom: 1px solid #e0e0e0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.content{
  min-width: initial;
  margin-top: 1rem;
  height: calc(100vh - 300px - 56px);
}
</style>
