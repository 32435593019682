<template>
  <LayoutModal
    :title="'채널'+dialogTitle"
    size="sm"
  >
    <div class="channelInfo">
      <CommonSelect
        v-model:options="$props.groups"
        v-model:selected="setGroup"
        label="그룹"
        :disabled="dialogTitle === '수정'"
      />
      <MDBInput
        label="IP"
        v-model="setIp"
      />
      <MDBInput
        label="PORT"
        v-model="setPort"
      />
      <MDBInput
        label="ID"
        v-model="setId"
      />
      <MDBInput
        label="채널"
        v-model="setName"
      />
    </div>

    <template #modalFooter>
      <CommonButton
        color="warning"
        @click="onClickAdd"
      >
        {{ dialogTitle }}
      </CommonButton>
      <CommonButton
        v-show="dialogTitle === '수정'"
        class="default-state"
        @click="onClickDelete"
      >
        삭제
      </CommonButton>
      <CommonButton
        class="default-state"
        @click="closeModal"
      >
        취소
      </CommonButton>
    </template>
  </LayoutModal>
</template>

<script setup>

import LayoutModal from '@/components/layout/LayoutModal.vue';
import CommonButton from '@/components/CommonButton.vue';
import CommonSelect from '@/components/CommonSelect.vue';
import {
  useAttrs, computed, watch, getCurrentInstance, ref,
} from 'vue';
import { STORE_SETUP_CHANNEL } from '@/store/modules/SetupChannel';

const props = defineProps({
  changeTarget: {
    type: Object,
    default() {
      return { };
    },
  },
  groups: {
    type: Array,
    default() {
      return [];
    },
  },
});

const internalInstance = getCurrentInstance();
const attrs = useAttrs();

const visible = computed(() => attrs.modelValue);
const dialogTitle = ref();
const setIp = ref();
const setPort = ref('');
const setId = ref('');
const setName = ref('');
const setGroup = ref();

watch(
  visible,
  (newVal) => {
    if (newVal) {
      if (props.changeTarget) {
        const {
          svc_id,
          mc_port,
          mc_ip,
          svc_name,
          group_id,
        } = props.changeTarget;
        setIp.value = mc_ip || '';
        setPort.value = mc_port || '';
        setId.value = svc_id || '';
        setName.value = svc_name || '';
        setGroup.value = group_id || '';
        dialogTitle.value = '수정';
      } else {
        setIp.value = '';
        setPort.value = '';
        setId.value = '';
        setName.value = '';
        setGroup.value = '';
        dialogTitle.value = '등록';
      }
    }
  },
);

function closeModal() {
  internalInstance.emit('update:modelValue', false);
}

async function requestDeleteChannel() {
  try {
    const { group_id, svc_id } = props.changeTarget;
    const res = await window.$axios.delete(`setups/channels/${svc_id}`, {
      params: {
        group_id, svc_id,
      },
    });
    const { code } = res.result;
    if (code === 200) {
      window.$util.Toast('채널이 삭제 되었습니다.');
      window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getList`);
      closeModal();
    }
  } catch (e) {
    window.$util.Error({
      url: 'setups/channels',
      e,
    });
  }
}

async function requestAddChannel() {
  try {
    const params = {
      mc_ip: setIp.value.trim(),
      mc_port: setPort.value.trim(),
      svc_id: setId.value.trim(),
      svc_name: setName.value.trim(),
      group_id: setGroup.value.trim(),
    };

    const hasEmpty = Object.values(params).some((x) => x === null || x === '');
    if (hasEmpty) {
      window.$util.Toast('입력값을 확인해주세요.');
      return;
    }
    const res = await window.$axios.put('setups/channels', params);
    const { code } = res.result;
    if (code === 200) {
      window.$util.Toast(`${dialogTitle.value}을 완료했습니다.`);
      window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getList`);
      closeModal();
    }
  } catch (e) {
    window.$util.Error({
      url: 'setups/channels',
      e,
    });
  }
}

function onClickAdd() {
  if (setName.value && setIp.value && setName.value && setPort.value) {
    requestAddChannel();
  } else {
    window.$util.Toast('입력값을 확인해주세요.');
  }
}

function onClickDelete() {
  window.$util.Confirm('채널 삭제', '채널을 삭제하시겠습니까?', requestDeleteChannel);
}

</script>

<style lang="scss">

.channelInfo .form-outline{
  margin-bottom: 15px;
}
.modal-footer{
  .btn{
    font-size: 0.8rem;
  }

}
.default-state{
  background-color: #232f3e;
  color: white;
}

</style>
