<template>
  <MDBModal
    v-model="visible"
    tabindex="-1"
  >
    <MDBModalHeader>
      <MDBModalTitle>{{ title }}</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      {{ body }}
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn
        color="secondary"
        @click="onCancelClick"
      >
        닫기
      </MDBBtn>
      <MDBBtn
        color="primary"
        @click="onOkClick"
      >
        확인
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import { computed } from 'vue';

const visible = computed(({
  get() {
    return window.$store.state.Confirm.visible;
  },
  set(value) {
    window.$store.commit('Confirm/setState', {
      visible: value,
    });
  },
}));

const title = computed(() => window.$store.state.Confirm.title);
const body = computed(() => window.$store.state.Confirm.body);
const okClick = computed(() => window.$store.state.Confirm.okClick);
const cancelClick = computed(() => window.$store.state.Confirm.cancelClick);

const onOkClick = () => {
  if (okClick?.value) {
    okClick.value();
  }
  visible.value = false;
};

const onCancelClick = () => {
  if (cancelClick?.value) {
    cancelClick.value();
  }

  visible.value = false;
};
</script>

<style scoped lang="scss">
.modal-body{
  white-space: pre;
}
</style>
