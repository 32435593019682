<template>
  <CommonGrid
    class-name="unmapped"
    :columns="columns"
    :datas="gridItems"
    :page-able="true"
    :page-idx="refCurrentPage"
    :total-page="totalPage"
    @change-page="onChangePage"
  >
    <template #header_checked>
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedAll"
      />
    </template>
    <template #checked="{caption_contents_seq}">
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedObj[caption_contents_seq]"
      />
    </template>
  </CommonGrid>
</template>

<script setup>
import CommonGrid from '@/components/CommonGrid.vue';

import { ref, watch } from 'vue';
import useUnmappedCaptionGrid from './unmappedCaptionGrid';

const emit = defineEmits(['selection-changed']);
const selected = ref();

watch(selected, (newValue) => {
  emit('selection-changed', newValue);
});

const { columns,
  refCurrentPage,
  gridItems,
  totalPage,
  checkedObj,
  checkedAll,
  onChangePage } = useUnmappedCaptionGrid();

</script>

<style lang="scss">
.unmapped{
  .accoItem{
    min-width: 990px;
    .form-check{
      flex: 0 0 50px;
      display: flex;
      justify-content: center;
    }
    .acco-number{
      flex: 0 0 56px;
    }
    .acco-date,
    .acco-episodenumber{
      flex: 0 0 100px;
    }
    .acco-chanel{
      flex: 0 0 120px;
    }
    .acco-filename{
      flex: 1 0 200px;
      text-align: left;
      word-break: break-all;
    }
  }
}
.btn-wrap{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  .btn{
    font-size: 14px;
  }
}
</style>
