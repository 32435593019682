<template>
  <div class="d-flex mb-3 mt-2">
    <div class="me-auto p-2">
      Total {{ totalCount }}건
    </div>
  </div>
</template>

<script setup>
import useStatusButtonGroup from '@/views/SyncStatus/syncStatusButtonGroup';

const {
  totalCount,
} = useStatusButtonGroup();

</script>
<style lang="scss" scoped>
.btn{
  font-size: 14px;
}
</style>
