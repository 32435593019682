<template>
  <LayoutBase>
    <template #search>
      <Search />
    </template>
    <template #searchGrid>
      <SearchGrid />
    </template>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/components/layout/LayoutBase.vue';

import Search from './ReSyncSearch/ReSyncSearchSearch.vue';
// import SearchGrid from './SyncSearch/SyncSearchGrid.vue';
import SearchGrid from './ReSyncSearch/ReSyncSearchGrid.vue';

export default {
  components: {
    LayoutBase,
    Search,
    SearchGrid,
  },
  async beforeRouteEnter(to, from, next) {
    window.$store.dispatch('ReSyncSearch/beforeRouteEnter');
    await window.$store.dispatch('ReSyncSearch/getList');
    next();
  },
};
</script>
