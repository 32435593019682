import { ref, computed } from 'vue';
import { STORE_SETUP_CHANNEL } from '@/store/modules/SetupChannel';

export default function useSetupChannelGrid() {
  const columns = Object.freeze([
    {
      field: 'checked',
      title: '선택',
      headerClassName: 'acco-select',
      className: 'acco-select',
    },
    {
      field: 'no',
      title: 'NO',
      headerClassName: 'acco-number',
      className: 'acco-number',
      sortable: false,
    },
    {
      field: 'svc_name',
      title: '채널',
      headerClassName: 'acco-chanelname',
      className: 'acco-chanelname',
      sortable: false,
    },
    {
      field: 'mc_ip',
      title: 'Multicast IP',
      headerClassName: 'acco-ip',
      className: 'acco-ip',
      sortable: false,
    },
    {
      field: 'svc_id',
      title: '서비스ID',
      headerClassName: 'acco-serverid',
      className: 'acco-serverid',
      sortable: false,
    },
    {
      field: 'status',
      title: '동작상태',
      headerClassName: 'acco-status',
      className: 'acco-status',
      sortable: false,
    },
  ]);

  const gridChecked = window.$util.computeGetSet(STORE_SETUP_CHANNEL, 'grid', 'checked');
  const checkedObj = ref(gridChecked);
  const sort = ref(null);
  const dataItems = computed(() => window.$store.getters[`${STORE_SETUP_CHANNEL}/dataItems`] || []);
  const groups = computed(() => window.$store.getters[`${STORE_SETUP_CHANNEL}/groups`] || []);
  const selectedGroup = window.$util.computeGetSet(STORE_SETUP_CHANNEL, 'search', 'group_id');
  const refGroups = ref(groups);
  const refSelectedGroup = ref(selectedGroup);

  const gridItems = computed(() => dataItems.value.map((obj, idx) => ({
    no: idx + 1,
    ...obj,
  })));

  function requestChangeGroup() {
    window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getList`);
  }

  return {
    columns,
    checkedObj,
    gridItems,
    refGroups,
    refSelectedGroup,
    requestChangeGroup,
    onSort(obj) {
      sort.value = obj;
    },

  };
}
