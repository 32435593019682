<template>
  <MDBModal
    v-model="visible"
    side
    position="bottom-right"
    direction="right"
    scrollable
    class="text-start"
  >
    <MDBModalHeader :color="color">
      <MDBModalTitle id="ModalJsonTitle">
        {{ title }}
      </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="pb-0">
      <pre style="margin-bottom: 0;">{{ body }}</pre>
    </MDBModalBody>
    <MDBModalFooter class="border-0 p-2" />
    <MDBModalFooter>
      <MDBBtn
        color="primary"
        @click="onClickCopy"
      >
        Copy
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import { computed } from 'vue';

const visible = computed(({
  get() {
    return window.$store.state.JsonModal.visible;
  },
  set(value) {
    window.$store.commit('JsonModal/setState', {
      visible: value,
    });
  },
}));
const title = computed(() => window.$store.state.JsonModal.title);
// const small = computed(() => window.$store.state.JsonModal.small);
const body = computed(() => window.$store.state.JsonModal.body);
const color = computed(() => window.$store.state.JsonModal.color);
const onClickCopy = () => {
  navigator.clipboard.writeText(body.value);
  visible.value = false;
};
</script>
