<template>
  <LayoutBase>
    <template #search>
      <Search />
    </template>
    <template #searchGrid>
      <SearchGrid />
    </template>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/components/layout/LayoutBase.vue';

import { STORE_CAPTION_VALIDATION } from '@/store/modules/CaptionValidation';
import Search from './CaptionValidation/CaptionValidationSearch.vue';
import SearchGrid from './CaptionValidation/CaptionValidationGrid.vue';

export default {
  components: {
    LayoutBase,
    Search,
    SearchGrid,
  },
  async beforeRouteEnter(to, from, next) {
    window.$store.dispatch(`${STORE_CAPTION_VALIDATION}/beforeRouteEnter`);
    await window.$store.dispatch(`${STORE_CAPTION_VALIDATION}/getList`);
    next();
  },
};
</script>
