import SyncStatus from './SyncStatus';
import searchMixin from './searchMixin';

export const STORE_SETUP_CHANNEL = 'SetupChannel';
export default {
  ...SyncStatus,
  state: {
    ...SyncStatus.state,
    url: 'setups/channels',
    urlGroup: 'setups/channels/groups',
    urlHealth: 'setups/channels/healths',
    urlScore: 'setups/validations/score',
    search: {
      group_id: undefined,
    },
    grid: {
      checked: {},
    },
    groups: [],
    healths: [],
  },
  mutations: {
    ...searchMixin.mutations,
  },
  actions: {
    ...SyncStatus.actions,
    setListItems({ commit }, apiResult) {
      const { channels } = apiResult;
      commit('dataItems', channels || []);
    },

    setGroup({ commit }, apiResult) {
      const { groups } = apiResult;
      const options = groups.map(({ group_id: value, group_name: text }, idx) => ({
        value, text, selected: idx === 0,
      }));
      commit('setState', {
        groups: options,
      });

      commit('setState', {
        search: {
          group_id: options[0].value,
        },
      });
    },
    setHealths({ commit }, apiResult) {
      const { healths } = apiResult;

      commit('setState', {
        healths,
      });
    },
    async getGroup({ commit, dispatch, state }, param) {
      // eslint-disable-next-line camelcase
      const { urlGroup } = state;
      const params = {
        ...param,
      };

      try {
        const res = await window.$axios.get(urlGroup, {
          params,
        });
        dispatch('setGroup', res);
      } catch (e) {
        commit('setState', {
          groups: [],
        });
      }
    },
    async getScore({ commit, state }) {
      // eslint-disable-next-line camelcase
      const { urlScore } = state;

      try {
        const res = await window.$axios.get(urlScore);
        const { score } = res;
        commit('setState', {
          score,
        });
      } catch (e) {
        commit('setState', {
          score: undefined,
        });
      }
    },
    beforeRouteEnter({ commit, dispatch }) {
      commit('setState', {});
      dispatch('clearCheckedItems');
    },
    async getList(ctx, param) {
      await searchMixin.actions.getList(ctx, param);
    },
    async getHealths({ commit, dispatch, state }, param) {
      const { urlHealth } = state;
      const params = {
        ...param,
      };

      try {
        const res = await window.$axios.get(urlHealth, {
          params,
        });
        dispatch('setHealths', res);
      } catch (e) {
        commit('setState', {
          healths: [],
        });
      }
    },

  },
  getters: {
    ...searchMixin.getters,

    groups: ({ groups }) => groups,
    healths: ({ healths }) => healths,
    score: ({ score }) => score,
  },
};
