<template>
  <div class="mt-4">
    <div
      id="header-content"
      class="ps-3 pe-3"
    >
      <h4>
        <span style="white-space: nowrap;">{{ username }}</span>
      </h4>
      <p>{{ email }}</p>
    </div>
    <hr class="mb-0">
  </div>
  <MDBSideNavMenu>
    <MDBSideNavItem
      v-for="(item, idx) in menuList"
      :key="idx"
    >
      <router-link
        active-class="active"
        exact-active-class="exact-active"
        class="sidenav-link"
        :to="{name: item.name}"
      >
        <MDBIcon
          :icon="item.meta.icon"
          class="fas fa-fw me-3"
        />
        {{ item.meta.title }}
      </router-link>
    </MDBSideNavItem>
  </MDBSideNavMenu>
  <hr class="m-0">
  <MDBSideNavMenu>
    <MDBSideNavItem
      v-for="(item, idx) in menuList2"
      :key="idx"
    >
      <router-link
        active-class="active"
        exact-active-class="exact-active"
        class="sidenav-link"
        :to="{name: item.name}"
      >
        <MDBIcon
          :icon="item.meta.icon"
          class="fas fa-fw me-3"
        />
        {{ item.meta.title }}
      </router-link>
    </MDBSideNavItem>
  </MDBSideNavMenu>
  <hr class="m-0">
</template>

<script setup>
import { MDBSideNavMenu, MDBSideNavItem } from 'mdb-vue-ui-kit';
import useHeader from './useHeader';

const { menuList, menuList2,
  username,
  email } = useHeader();
</script>
