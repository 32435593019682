import { computed } from 'vue';
import { STORE_SETUP_CHANNEL } from '@/store/modules/SetupChannel';

// eslint-disable-next-line no-underscore-dangle
const _util = {
  computeGetSet: (path, midKey, key) => computed({
    get() {
      return window.$store.state[path][midKey][key];
    },
    set(value) {
      window.$store.commit(`${path}/${midKey}`, {
        [key]: value,
      });
    },
  }),
  sortString(arr, field, asc) {
    arr.sort((a, b) => {
      const upperCaseA = a[field].toUpperCase(); // ignore upper and lowercase
      const upperCaseB = b[field].toUpperCase(); // ignore upper and lowercase
      if (upperCaseA < upperCaseB) return asc ? 1 : -1;
      if (upperCaseA > upperCaseB) return asc ? -1 : 1;
      return 0;
    });
  },
  Loading: (bshow) => window.$store.dispatch('showLoading', bshow),
  Toast: (body, color = 'primary') => {
    window.$store.dispatch('Alert/show', {
      body, color,
    });
  },
  Confirm: (title, body, okClick, cancelClick) => {
    window.$store.dispatch('Confirm/show', {
      title, body, okClick, cancelClick,
    });
  },
  Error: (body, color = 'danger') => {
    /*  window.$store.dispatch('JsonModal/show', {
      title: '에러',
      color,
      body,
    }); */

    window.$store.dispatch('Alert/show', {
      body, color,
    });
  },
  HealthCheck: async () => {
    await window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getHealths`);
    const healths = computed(() => window.$store.getters[`${STORE_SETUP_CHANNEL}/healths`]);
    if (healths.value.some((obj) => !obj.is_live)) {
      window.$util.Confirm('경고', '장애서버가 있습니다.\n설정 관리 화면으로 이동하시겠습니까?', () => {
        window.$router?.push('setup-channel');
      }, () => { window.$router.go(); });
    } else {
      window.$router.go();
    }
  },
  SaveBlob: async (response) => {
    let filename = '';
    const disposition = response.headers['content-disposition'];
    if (disposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const blob = await new Blob([response.data]);
    const fileObjectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileObjectUrl;
    link.style.display = 'none';
    link.download = decodeURI(filename);

    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(fileObjectUrl);
  },
};

export default {
  install(app) {
    window.$util = _util;
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$util = _util;
    app.provide('$util', _util);
  },
};
