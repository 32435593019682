<template>
  <div
    id="content"
    class="d-flex flex-column contentsWrap"
    :class="className"
  >
    <header class="w-100">
      <div class="headerWrap">
        <MDBNavbarBrand href="#/">
          <img
            alt="logo"
            src="@/assets/skb_ci_xs.png"
            class="logo"
          >
        </MDBNavbarBrand>
        <div class="navTools">
          <MDBNavbarNav class="d-flex flex-row">
            <MDBNavbarItem
              to="/Logout"
              class="logout"
            >
              <MDBIcon icon="sign-out-alt" />
            </MDBNavbarItem>
          </MDBNavbarNav>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <MDBNavbarToggler
          aria-controls="#sidenavPositions"
          @click="sidenavPositions = !sidenavPositions"
          class="iconMenu px-3 py-2"
        >
          <i class="fas fa-bars" />
        </MDBNavbarToggler>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <router-link :to="{name: routeName}">
              {{ title }}
            </router-link>
          </MDBBreadcrumbItem>
          <!--
            <MDBBreadcrumbItem><a href="#">Library</a></MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>
              <a href="#">Data</a>
            </MDBBreadcrumbItem>
          -->
        </MDBBreadcrumb>
      </nav>
    </header>
    <slot />
  </div>
</template>

<script setup>
import {
  computed, watch, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBBreadcrumb, MDBBreadcrumbItem, MDBNavbarItem,
} from 'mdb-vue-ui-kit';

const sidenavPositions = computed(({
  get() {
    return window.$store.state.sidenavPositions;
  },
  set(value) {
    window.$store.dispatch('toggleSidenav', value);
  },
}));

const className = computed(() => sidenavPositions.value === false && 'active');

const route = useRoute();
const title = ref(route.meta?.title);
const routeName = ref(route.name);
watch(route, (newVal) => {
  title.value = newVal.meta?.title;
  routeName.value = newVal.name;
});
</script>

<style lang="scss" scoped>
.active {
  padding-left: 0px !important;
}
</style>
