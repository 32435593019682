<template>
  <CommonButton
    outline="danger"
    icon="history"
    icon-style="fas"
    @click="getList()"
    class="btn-update"
  >
    마지막 업데이트 : {{ lastUpdateTime }}
  </CommonButton>
</template>

<script setup>
import { computed } from 'vue';
import CommonButton from '@/components/CommonButton.vue';

const getList = (params) => window.$store.dispatch('SyncStatus/getList', params);
const lastUpdateTime = computed(() => window.$store.getters['SyncStatus/lastUpdateTime']);

</script>
<style>
.btn-update{
  min-height: 36px;
}
</style>
