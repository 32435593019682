import { createApp } from 'vue';
import { Amplify } from 'aws-amplify';
import axios from '@/plugins/axios';
import mdb5 from '@/plugins/mdb5';
import util from '@/plugins/util';
import awsExports from './aws-exports';
import App from './App.vue';
import router from './router';
import store from './store';

Amplify.configure(awsExports);

const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
  // 오류 처리(예: 서비스에 보고)
  console.error('[ERROR]', err, instance, info);
};

// use
app.use(store)
  .use(router)
  .use(axios)
  .use(mdb5)
  .use(util)
  // mount
  .mount('#app');
