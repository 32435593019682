import { ref, computed } from 'vue';
import { STORE_RESYNC_SEARCH } from '@/store/modules/ReSyncSearch';
import moment from 'moment';

export default function useEncoderHistorySearchGrid() {
  const columns = Object.freeze([
    {
      field: 'checked',
    },
    {
      field: 'no',
      title: 'No',
      headerClassName: 'acco-number',
      className: 'acco-number',
      sortable: false,
    },
    {
      field: 'brcast_dt',
      title: '방송일',
      headerClassName: 'acco-date',
      className: 'acco-date',
      sortable: false,
    },
    {
      field: 'channel_nm',
      title: '채널',
      headerClassName: 'acco-channel',
      className: 'acco-channel',
      sortable: false,
    },
    {
      field: 'epsd_nm',
      title: '에피소드',
      headerClassName: 'acco-episodename',
      className: 'acco-episodename',
      sortable: false,
    },
    {
      field: 'epsd_id',
      title: '에피소드ID',
      headerClassName: 'acco-episodeid',
      className: 'acco-episodeid',
      sortable: false,
    },
    {
      field: 'sris_nm',
      title: '시리즈',
      headerClassName: 'acco-episodename',
      className: 'acco-episodename',
      sortable: false,
    },
    {
      field: 'sris_id',
      title: '시리즈ID',
      headerClassName: 'acco-episodeid',
      className: 'acco-episodeid',
      sortable: false,
    },

    {
      field: 'brcast_rseq',
      title: '회차',
      headerClassName: 'acco-episodenumer',
      className: 'acco-episodenumer',
      sortable: false,
    },
    {
      field: 'vod_file_nm',
      title: '영상 파일명',
      headerClassName: 'acco-filename',
      className: 'acco-filename',
      sortable: false,
    },
  ]);

  const ID_FIELD = 'brcast_contents_seq';

  const refCurrentPage = window.$util.computeGetSet(STORE_RESYNC_SEARCH, 'search', 'offset_page');

  const checkedObj = window.$util.computeGetSet(STORE_RESYNC_SEARCH, 'grid', 'checked');
  const totalPage = window.$util.computeGetSet(STORE_RESYNC_SEARCH, 'pagination', 'total_page_num');
  const limit = window.$util.computeGetSet(STORE_RESYNC_SEARCH, 'pagination', 'limit');

  const sort = ref(null);
  const dataItems = computed(() => window.$store.getters[`${STORE_RESYNC_SEARCH}/dataItems`] || []);

  const gridItems = computed(() => {
    const startNum = limit.value * (refCurrentPage.value - 1) + 1;
    return dataItems.value.map((obj, idx) => ({
      no: startNum + idx,
      checked: !!checkedObj.value[obj[ID_FIELD]],
      ...obj,
      brcast_dt: moment(obj.brcast_dt).format('YYYY-MM-DD'),
    }));
  });

  const checkedAll = computed(({
    get() {
      if (gridItems.value?.length === 0) return false;
      return !gridItems.value.find((obj) => !obj.checked);
    },
    set(value) {
      dataItems.value.forEach((obj) => {
        checkedObj.value[obj[ID_FIELD]] = value;
      });
    },
  }));

  return {
    columns,
    checkedObj,
    refCurrentPage,
    gridItems,
    checkedAll,
    totalPage,
    onSort(obj) {
      sort.value = obj;
    },
    onChangePage(idx) {
      refCurrentPage.value = idx;
      window.$store.dispatch(`${STORE_RESYNC_SEARCH}/getList`);
    },
  };
}
