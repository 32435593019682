/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ref } from 'vue';

Chart.register(ChartDataLabels);
function generateCharts(
  canvasRef,
  type,
  data,
  options,
  plugins,
) {
  const _chart = ref(null);
  const _data = ref(data);
  const _options = ref(options);
  const _plugins = ref(plugins);

  function destroyChart() {
    if (_chart.value) {
      _chart.value.destroy();
      _chart.value = null;
    }
  }

  function renderChart() {
    destroyChart();

    if (canvasRef instanceof HTMLCanvasElement) {
      _chart.value = new Chart(canvasRef.getContext('2d'), {
        type,
        data: _data.value,
        options: _options.value,
        plugins: [_plugins.value],
      });
    }
  }

  function updateChart(updateData, updateOptions = null, updatePlugins = null) {
    _data.value = updateData;
    _options.value = updateOptions;
    _plugins.value = updatePlugins;

    renderChart();
  }

  return {
    renderChart,
    destroyChart,
    updateChart,
  };
}

export default generateCharts;
