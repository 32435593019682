<template>
  <div>
    <MDBListGroup class="container-list">
      <MDBListGroupItem
        class="d-flex justify-content-between align-items-center"
        v-for="(item, idx) in statistics"
        :key="idx"
      >
        {{ item.sync_status_nm }}
        <span class="badge badge-primary badge-pill"> {{ item.counts }}</span>
      </MDBListGroupItem>
    </MDBListGroup>
  </div>
</template>

<script setup>
import { MDBListGroup, MDBListGroupItem } from 'mdb-vue-ui-kit';
import { computed } from 'vue';
import { STORE_SYNC_SEARCH } from '@/store/modules/SyncSearch';

const statistics = computed(() => window.$store.getters[`${STORE_SYNC_SEARCH}/statistics`] || []);
</script>

<style lang="scss" scoped>
.accordion-item{
  overflow: hidden;

}
.container-list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
    .list-group-item {
      line-height: 1px;
      font-size: 14px;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      border: 1px solid rgba(0, 0, 0, 0.125);
      &:nth-child(n+4):nth-child(-n+6){
        border-top:0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
}
</style>
