import { ref, computed } from 'vue';
import moment from 'moment';
import { STORE_CAPTION_VALIDATION } from '@/store/modules/CaptionValidation';

export default function useCaptionValidationSearch() {
  const searchFromDate = window.$util.computeGetSet(STORE_CAPTION_VALIDATION, 'search', 'brcast_start_dt');
  const searchToDate = window.$util.computeGetSet(STORE_CAPTION_VALIDATION, 'search', 'brcast_end_dt');

  const refCurrentPage = window.$util.computeGetSet(STORE_CAPTION_VALIDATION, 'search', 'offset_page');
  const searchResultType = window.$util.computeGetSet(STORE_CAPTION_VALIDATION, 'search', 'result_type');
  const searchChannle = window.$util.computeGetSet(STORE_CAPTION_VALIDATION, 'search', 'channel_nm');

  const refResultType = ref(searchResultType);
  const refChannel = ref(searchChannle);

  const date = computed({
    get() {
      const searchFromDt = moment(searchFromDate.value);
      const searchToDt = moment(searchToDate.value);
      return [
        searchFromDt.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        searchToDt.hour(23)
          .minute(59)
          .second(0)
          .millisecond(0)
          .toDate(),
      ];
    },
    set(value) {
      const from = value[0]?.valueOf();
      const fromYYYYMMDD = moment(from).format('YYYYMMDD');

      const to = value[1]?.valueOf();
      const toYYYYMMDD = moment(to).format('YYYYMMDD');
      if (fromYYYYMMDD !== searchFromDate.value || toYYYYMMDD !== searchToDate.value) {
        window.$store.commit(`${STORE_CAPTION_VALIDATION}/search`, {
          brcast_start_dt: fromYYYYMMDD,
          brcast_end_dt: toYYYYMMDD,

        });
      }
    },
  });

  const requestDownload = async () => {
    try {
      const response = await window.$axios.get(
        'mappings/validation-tool',
        {
          responseType: 'blob',
        },
      );
      await window.$util.SaveBlob(response);
    } catch (e) {
      window.$util.Toast('다운로드에 실패했습니다.');
      throw new Error('파일 다운로드 실패');
    }
  };

  function getList(page) {
    refCurrentPage.value = page || 1;
    window.$store.dispatch(`${STORE_CAPTION_VALIDATION}/getList`);
  }

  return {
    date,
    refResultType,
    requestDownload,
    getList,
    refChannel,
  };
}
