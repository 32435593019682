<template>
  <DialogLoading />
  <Alert />
  <ModalJson />
  <AuthMain>
    <template v-if="authStatus === 'configuring'">
      <button @click="auth.signOut">
        configuring...
      </button>
    </template>
    <template v-else>
      <HeaderNav>
        <div class="content p-3">
          <router-view #default="{ Component }">
            <template v-if="Component">
              <KeepAlive>
                <Suspense>
                  <!-- main content -->
                  <Component :is="Component" />
                  <!-- loading state -->
                  <template #fallback>
                    Loading...
                  </template>
                </Suspense>
              </KeepAlive>
            </template>
          </router-view>
        </div>
        <Footer />
      </HeaderNav>
    </template>
  </AuthMain>
  <div id="modal" />
  <Confirm />
</template>

<script setup>
import useAuthenticator from '@/composables/useAuthenticator';

import AuthMain from '@/components/auth/AuthMain.vue';
import HeaderNav from '@/components/header/HeaderNav.vue';
import Footer from '@/components/footer/Footer.vue';
import DialogLoading from '@/components/DialogLoading.vue';
import ModalJson from '@/components/ModalJson.vue';
import Alert from '@/components/CommonAlert.vue';
import Confirm from '@/components/CommonConfirm.vue';

const { authStatus } = useAuthenticator();
</script>
<style scoped lang="scss">

</style>
