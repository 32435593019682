export default {
  namespaced: true,
  state: {
    visible: false,
    title: '',
    body: '',
    okClick: undefined,
    cancelClick: undefined,
  },
  mutations: {
    setState(state, item) {
      Object.keys(item)
        .forEach((key) => {
          state[key] = item[key];
        });
    },
  },
  actions: {
    show({ commit }, obj) {
      commit('setState', {
        visible: true,
        ...obj,
      });
    },
  },
  getters: {
    state: (state) => state,
  },
};
