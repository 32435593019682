<template>
  <div class="content">
    <div class="title">
      # 자막검수툴 설정
    </div>
    <SetupTool />
    <div class="horizontal-line" />
    <div class="title">
      # 서버상태
    </div>
    <ServerGrid />
    <div class="horizontal-line" />
    <div class="title">
      # 채널정보
    </div>
    <SearchGrid />
  </div>
</template>

<script>

import { STORE_SETUP_CHANNEL } from '@/store/modules/SetupChannel';
import SearchGrid from './SetupChannel/SetupChannelGrid.vue';
import ServerGrid from './SetupChannel/SetupServerGrid.vue';
import SetupTool from './SetupChannel/SetupTool.vue';

export default {
  components: {
    SearchGrid,
    ServerGrid,
    SetupTool,
  },
  async beforeRouteEnter(to, from, next) {
    await window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getGroup`);
    await window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getList`);
    await window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getHealths`);
    await window.$store.dispatch(`${STORE_SETUP_CHANNEL}/getScore`);
    next();
  },

};
</script>

<style lang="scss" scoped>

.content{
  min-width: initial;
  margin-top: 1rem;
  .title{
    font-weight: bold;
    text-align: left;
  }
}
.horizontal-line{
  min-height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 1rem;
}
</style>
