<template>
  <div>
    <div class="chart-title">
      {{ title }}
    </div>
    <MDBChart
      class="w-100"
      v-if="data.labels?.length"
      :data="data"
      :plugins="plugins"
      :options="props.options"
      datalabels
    />
    <div
      v-else
      class="chart-description-empty"
    >
      데이터 없음
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import MDBChart from '@/components/MDB/MDBChart/MDBChartDoughnut.vue';

import { defaults } from 'chart.js';
import {
  toLineHeight, valueOrDefault, isNullOrUndef,
} from 'chart.js/helpers';

const defaultFont = defaults.font;
defaultFont.family = "'NanumGothic', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  options: {
    type: Object,
    default() {
      return DEFAULT_OPTIONS;
    },
  },
});

const toFontString = (font) => {
  if (!font || isNullOrUndef(font.size)) {
    return null;
  }

  return `${(font.style ? `${font.style} ` : '') + (font.weight ? `${font.weight} ` : '') + font.size}px ${font.family}`;
};

const parseFont = (value) => {
  const size = valueOrDefault(value.size, defaultFont.size);
  const font = {
    ...defaultFont,
    lineHeight: toLineHeight(value.lineHeight, size),
    size,
    style: valueOrDefault(value.style, defaultFont.style),
    weight: value.weight || null,
    string: '',
  };

  font.string = toFontString(font);
  return font;
};

const textSize = (ctx, labels) => {
  const items = [].concat(labels);
  const ilen = items.length;
  const prev = ctx.font;
  let width = 0;
  let height = 0;
  let i;

  for (i = 0; i < ilen; ++i) {
    ctx.font = items[i].font.string;
    width = Math.max(ctx.measureText(items[i].text).width, width);
    height += items[i].font.lineHeight;
  }

  ctx.font = prev;

  return {
    height,
    width,
  };
};

const plugins = {
  id: 'doughnutlabel',
  // eslint-disable-next-line no-shadow
  beforeDatasetDraw: (chart, arg, options) => {
    const { data, ctx, width } = chart;
    const { datasets } = data;
    const dataset = datasets[0];
    const datasetData = dataset.data;
    const total = datasetData.reduce((a, b) => a + b, 0);

    const innerLabels = [];
    /*   innerLabels.push({
      text: '시도',
      font: parseFont({
        lineHeight: width > 210 ? 1.2 : 0.8,
        size: width > 210 ? 20 : 15,
        weight: 400,
      }),
    }); */
    innerLabels.push({
      text: `${total}건`,
      font: parseFont({
        lineHeight: width > 210 ? 1.5 : 1.0,
        size: width > 210 ? 30 : 22,
      }),
    });

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    const textAreaSize = textSize(ctx, innerLabels);
    const topY = centerY - textAreaSize.height / 2;

    let i;
    const ilen = innerLabels.length;
    let currentHeight = 0;
    for (i = 0; i < ilen; ++i) {
      ctx.fillStyle = innerLabels[i].color;
      ctx.font = innerLabels[i].font.string;

      // The Y center of each line
      const lineCenterY = topY + innerLabels[i].font.lineHeight / 2 + currentHeight;
      currentHeight += innerLabels[i].font.lineHeight;

      // Draw each line of text
      ctx.fillText(innerLabels[i].text, centerX, lineCenterY);
    }
  },
};

</script>

<script>
const DEFAULT_OPTIONS = {
  plugins: {
    tooltip: false,
    legend: {
      position: 'right',
      labels: {
        padding: 15,
        boxWidth: 20,
        boxHeight: 20,
        font: {
          size: '12',
        },
      },
    },
    datalabels: {
      formatter: (value, context) => context.chart.data.datalabels[context.dataIndex],
      color: 'black',
      labels: {
        title: {
          font: {
            size: '10',
          },
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1.2,

};
export default {};
</script>
<style lang="scss">
canvas{
  max-width: 450px;
  max-height:225px;
}
  .article-title{
    text-align: left;
    font-weight: bold;
  }
  .cart-wrap{
    flex-wrap: wrap !important;
    display: flex;
  }

  .chart-title{
    font-weight: bold;
    margin-bottom: 1rem;
  }

.chart-description-empty{
  margin: 4rem auto;
  font-weight: bold;
}

</style>
