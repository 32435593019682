<template>
  <MDBSelect
    attr
    select-all-label="전체"
    ref="selectRef"
  />
</template>

<script setup>
import {
  ref, watch, computed,
} from 'vue';
import { MDBSelect } from 'mdb-vue-ui-kit';

const selectRef = ref(null);
const clear = () => {
  selectRef.value.clear();
};
defineExpose({
  clear,
});

const selected = computed(() => selectRef.value?.selected);
watch(selected, (newVal, oldVal) => {
  if (oldVal?.length > 0 && newVal?.length === 0) {
    clear();
  }
});

</script>
