import { createStore } from 'vuex';
import SyncStatus from './modules/SyncStatus';
/* import EncoderTest from './modules/EncoderTest';
import ProfileInfo from './modules/ProfileInfo';
import SetInfo from './modules/SetInfo'; */
import SyncSearch from './modules/SyncSearch';
import ReSyncSearch from './modules/ReSyncSearch';
import CaptionSearch from './modules/CaptionSearch';
import CaptionValidation from './modules/CaptionValidation';
import SetupChannel from './modules/SetupChannel';
import UnmappedCaption from './modules/UnmappedCaption';

import Alert from './modules/Alert';
import Confirm from './modules/Confirm';
import JsonModal from './modules/JsonModal';

const store = createStore({
  state: {
    Loading: false,
    sidenavPositions: true,
  },
  getters: { },
  mutations: { },
  actions: {
    showLoading({ state }, bshow) { state.Loading = bshow; },
    toggleSidenav({ state }, toggle) { state.sidenavPositions = toggle; },
  },
  modules: {
    SyncStatus,
    SyncSearch,
    // EncoderTest,
    // ProfileInfo,
    // SetInfo,
    ReSyncSearch,
    CaptionSearch,
    CaptionValidation,
    SetupChannel,
    UnmappedCaption,

    Alert,
    Confirm,
    JsonModal,
  },
});

export default {
  ...store,
  install(app) {
    store.install(app);
    window.$store = app.config.globalProperties.$store;
  },
};
