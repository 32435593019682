import { createRouter, createWebHashHistory } from 'vue-router';
import { Auth } from 'aws-amplify';

import NoneGroups from '@/views/NoneGroups.vue';
import SyncStatus from '@/views/SyncStatus.vue';
import SyncSearch from '@/views/SyncSearch.vue';
import ReSyncSearch from '@/views/ReSyncSearch.vue';
import CaptionValidation from '@/views/CaptionValidation.vue';
import SetupChannel from '@/views/SetupChannel.vue';
import UnmappedCaption from '@/views/UnmappedCaption.vue';
// import EncoderTest from '@/views/EncoderTest.vue';
// import ProfileInfo from '@/views/ProfileInfo.vue';
// import SetInfo from '@/views/SetInfo.vue';

import /* useAuthenticator, */{
  // useUserGrade,
  DEFINE_USER_GRADE_ADMIN,
  DEFINE_USER_GRADE_OPERATOR,
  DEFINE_USER_GRADE_NONE,
} from '@/composables/useAuthenticator';

const routes = [
  {
    path: '/noneGroups',
    name: 'NoneGroups',
    component: NoneGroups,
    meta: {
      groups: false,
      gradeMin: DEFINE_USER_GRADE_NONE,
    },
  },
  {
    path: '/',
    alias: '/sync-status',
    name: 'SyncStatus',
    component: SyncStatus,
    meta: {
      menu: true,
      gradeMin: DEFINE_USER_GRADE_OPERATOR,
      title: '자막동기화 현황',
      icon: 'tachometer-alt',
    },
  },
  {
    path: '/sync-search',
    name: 'SyncSearch',
    component: SyncSearch,
    meta: {
      menu: true,
      gradeMin: DEFINE_USER_GRADE_ADMIN,
      title: '자막동기화 조회',
      icon: 'search',
    },
  },
  {
    path: '/resync',
    alias: '/resync',
    name: 'ReSyncSearch',
    component: ReSyncSearch,
    meta: {
      menu: true,
      gradeMin: DEFINE_USER_GRADE_OPERATOR,
      title: '자막 수동매핑',
      icon: 'exclamation-triangle',
    },
  },
  {
    path: '/validate',
    alias: '/validate',
    name: 'validate',
    component: CaptionValidation,
    meta: {
      menu: true,
      gradeMin: DEFINE_USER_GRADE_OPERATOR,
      title: '자막검수',
      icon: 'sliders-h',
    },
  },
  {
    path: '/unmapped',
    alias: '/unmapped',
    name: 'unmapped',
    component: UnmappedCaption,
    meta: {
      menu: true,
      gradeMin: DEFINE_USER_GRADE_OPERATOR,
      title: '미매핑 자막',
      icon: 'question',
    },
  },
  {
    path: '/setup-channel',
    alias: '/setup-channel',
    name: 'setup-channel',
    component: SetupChannel,
    meta: {
      menu: true,
      gradeMin: DEFINE_USER_GRADE_OPERATOR,
      title: '설정 관리',
      icon: 'cog',
    },
  },
  /*
  {
    path: '/encoderTest',
    name: 'EncoderTest',
    component: EncoderTest,
    meta: {
      menu: true,
      gradeMin: DEFINE_USER_GRADE_OPERATOR,
      title: '테스트 하기',
      icon: 'tasks',
    },
  },
  {
    path: '/profileInfo',
    name: 'ProfileInfo',
    component: ProfileInfo,
    meta: {
      setting: true,
      gradeMin: DEFINE_USER_GRADE_ADMIN,
      title: '프로파일 관리',
      icon: 'user',
    },
  },
  {
    path: '/setInfo',
    name: 'SetInfo',
    component: SetInfo,
    meta: {
      setting: true,
      gradeMin: DEFINE_USER_GRADE_ADMIN,
      title: '설정 관리',
      icon: 'cogs',
    },
  },

   */
  {
    path: '/Logout',
    name: 'Logout',
    meta: {
      setting: false,
      gradeMin: DEFINE_USER_GRADE_OPERATOR,
      title: 'Logout',
      icon: 'user-astronaut',
    },
    beforeEnter: async () => {
      await Auth.signOut();
      // reject the navigation
      return false;
      /*
      return {
        path: '/',
      }; */
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
/*
router.beforeEach(async (to) => {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser();
  } catch (e) {
    if (e === 'No current user' || e === 'The user is not authenticated') {
      if (to.path !== '/') {
        return {
          path: '/',
        };
      }
      return true;
    }
  }
  const { userGrade, existCognitoGroups } = useUserGrade(user);
  const { authStatus } = useAuthenticator();

  if (authStatus.value === 'unauthenticated') return true;
  if (to.path === '/noneGroups') {
    if (existCognitoGroups.value) {
      return {
        path: '/',
      };
    }
    return true;
  }
  if (!existCognitoGroups.value) {
    return {
      path: '/noneGroups',
    };
  }
  if (to.meta.gradeMin > userGrade.value) {
    return {
      path: '/',
    };
  }
  return true;
}); */

export default {
  ...router,
  install(app) {
    router.install(app);
    window.$route = app.config.globalProperties.$route;
    window.$router = app.config.globalProperties.$router;
  },
};
