<template>
  <MDBSideNav
    v-model="sidenavPositions"
    id="sidenavPositions"
    absolute
    mode="side"
    content-selector="#content"
  >
    <HeaderSideNavMenu />
  </MDBSideNav>
</template>

<script setup>
import { computed } from 'vue';
import { MDBSideNav } from 'mdb-vue-ui-kit';
import HeaderSideNavMenu from './HeaderSideNavMenu.vue';

const sidenavPositions = computed(({
  get() {
    return window.$store.state.sidenavPositions;
  },
  set(value) {
    window.$store.dispatch('toggleSidenav', value);
  },
}));

</script>
