<template>
  <div class="gridControlWrap">
    <div class="searchWrap">
      <CommonDatepicker v-model="date" />
      <div class="searchBox">
        <CommonInput
          label="자막파일명"
          v-model="refContentName"
          class="input-caption"
          @keyup.enter="getList()"
        />
        <CommonInput
          label="채널명"
          v-model="refChannel"
          @keyup.enter="getList()"
        />
        <CommonButton
          class="btnSearch"
          @click="getList()"
        >
          검색
        </CommonButton>
        <CommonButton
          class="px-3 py-2 btn-tool"
          color="warning"
          @click="requestDownload"
          icon="file-archive"
          icon-style="fas"
          :disabled="!downloadable"
        >
          다운로드
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import CommonDatepicker from '@/components/CommonDatepicker.vue';
import CommonButton from '@/components/CommonButton.vue';
import CommonInput from '@/components/CommonInput.vue';
import useUnmappedCaptionSearch from '@/views/UnmappedCaption/unmappedCaptionSearch';
import { STORE_UNMAPPED_CAPTION } from '@/store/modules/UnmappedCaption';
import { computed } from 'vue';

const checkedObj = computed(() => window.$store.getters[`${STORE_UNMAPPED_CAPTION}/checkedItems`]);
const downloadable = computed(() => (!!checkedObj.value.length));
const {
  date,
  getList,
  refContentName,
  requestDownload,
  refChannel,
} = useUnmappedCaptionSearch();

</script>
<style lang="scss" scoped>
.searchBox{
  display: flex;
  align-items: center;
  .sort-term{
    margin:0 0.5rem 0 0;
  }
}
</style>
