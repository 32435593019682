<template>
  <CommonGrid
    class-name="syncstatussearch"
    :columns="columns"
    :datas="gridItems"
    @sort="onSort"
    :page-able="false"
    :page-idx="refCurrentPage"
    @change-page="onChangePage"
  >
    <!--    <template #header_checked>
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedAll"
      />
    </template>
    <template #checked="{job_id}">
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedObj[job_id]"
      />
    </template>-->
    <template #progress="{progress, sync_server_result}">
      <div class="acco-process">
        <div class="accoContent-cell">
          <MDBProgress>
            <MDBProgressBar
              :value="progress"
              striped
              bg="success"
            />
            <div
              class="text"
              :class="{'text-danger': sync_server_result === '실패'}"
            >
              {{ progress }}%
            </div>
          </mdbprogress>
        </div>
      </div>
    </template>

    <template #schedule_stop="{job_id, sync_status_cd}">
      <div class="acco-stop">
        <CommonButton
          v-if="sync_status_cd === 'SYU002'"
          @click="onClickStop(job_id)"
          class=" btn-primary bg-gradient btn-stop-schedule"
        >
          중지
        </CommonButton>
      </div>
    </template>
    <template #sync_status="{ sync_status, sync_server_result}">
      <div
        class="acco-status"
        :class="{'text-danger' : sync_server_result === '실패' ,
                 'col-waiting': sync_status === '검수대기'}"
      >
        {{ sync_status }}
      </div>
    </template>
  </CommonGrid>
</template>

<script setup>
import CommonGrid from '@/components/CommonGrid.vue';
import CommonButton from '@/components/CommonButton.vue';

import useEncoderJobSearchGrid from './syncStatusSearchGrid';

const { columns,
  // checkedObj,
  refCurrentPage,
  gridItems,
  // checkedAll,
  requestChangeSyncSchedule,
  onSort,
  onChangePage } = useEncoderJobSearchGrid();

function onClickStop(job_id) {
  window.$util.Confirm('확인', '동기화를 중지하시겠습니까?', () => {
    requestChangeSyncSchedule(job_id);
  });
}

</script>
<style lang="scss">
.syncstatussearch{
  .accoItem{
    .form-check{
      flex: 0 0 50px;
      display: flex;
      justify-content: center;
    }
    &.accoHeader .tit:first-child,
    .acco-episode,
    .acco-series{
      flex: 1 0 10%;
      text-align: left;
      word-break: break-all;
    }
    .acco-status{
      flex: 0 0 160px;
    }
    .acco-type,
    .acco-process{
      flex: 0 0 140px;
    }
    .acco-process{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  /*  .progress {
      position: relative;
      border-radius: 20px;
      width: 100px;
      min-width: 100px;
      height: 20px;
      margin-top: 2px;
      .text{
        position: absolute;
        width: 100px;
        color: navy;
        font-weight: 500;
        line-height: 20px;
      }
    }*/

    .acco-captionname
    {
      flex: 1 0 220px;
      text-align: left;
      word-break: break-all;
    }

    .acco-stop{
      flex: 0 0 90px;
      padding: 0;
      .btn-stop-schedule{
        width: 50px;
        height: 26px;
        padding: 0;
      }
    }

  }

  .progress-bar{
    flex: initial !important;

  }

}

</style>
