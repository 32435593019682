import { computed } from 'vue';
import { STORE_SYNC_STATUS } from '@/store/modules/SyncStatus';

export default function useStatusButtonGroup() {
  const totalCount = computed(() => window.$store.getters['SyncStatus/totalCount']);

  async function requestChangeSyncSchedule(action) {
    const arr = window.$store.getters[`${STORE_SYNC_STATUS}/checkedItems`];
    if (arr.length === 0) {
      return;
    }
    const jobs = [];
    arr.forEach((key) => {
      jobs.push({
        action,
        job_id: key,
      });
    });

    const res = await window.$axios.patch('synctoday/actions', jobs);
    const { code, message } = await res.result;
    if (code !== 200) {
      window.$util.Error({
        url: 'synctoday/actions',
      });
      throw new Error(`${message} while requesting synctoday/actions`);
    }
    window.$store.dispatch(`${STORE_SYNC_STATUS}/clearCheckedItems`);
    window.$store.dispatch(`${STORE_SYNC_STATUS}/getList`);
  }

  return {
    requestChangeSyncSchedule,
    totalCount,
  };
}
