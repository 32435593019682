import { ref, computed } from 'vue';
import { STORE_SYNC_SEARCH } from '@/store/modules/SyncSearch';
import moment from 'moment';

export default function useEncoderHistorySearchGrid() {
  const columns = Object.freeze([
    {
      field: 'checked',
    },
    {
      field: 'no',
      title: 'NO',
      headerClassName: 'acco-number',
      className: 'acco-number',
      sortable: false,
    },
    {
      field: 'brcast_dt',
      title: '방송일',
      headerClassName: 'acco-date',
      className: 'acco-date',
      sortable: false,
    },
    {
      field: 'reg_dt',
      title: '등록일',
      headerClassName: 'acco-registration-date',
      className: 'acco-registration-date',
      sortable: false,
    },
    {
      field: 'brcast_type',
      title: '분류',
      headerClassName: 'acco-type',
      className: 'acco-type',
      sortable: false,
    },
    {
      field: 'sync_status',
      title: '처리상태',
      headerClassName: 'acco-status',
      className: 'acco-status',
      sortable: false,
    },
    {
      field: 'channel_nm',
      title: '채널',
      headerClassName: 'acco-channel',
      className: 'acco-channel',
      sortable: false,
    },
    {
      field: 'sris_nm',
      title: '시리즈',
      headerClassName: 'acco-series',
      className: 'acco-series',
      sortable: false,
    },
    {
      field: 'epsd_nm',
      title: '에피소드',
      headerClassName: 'acco-episode',
      className: 'acco-episode',
      sortable: false,
    },
    {
      field: 'caption_file_nm',
      title: '자막파일명',
      headerClassName: 'acco-captionname',
      className: 'acco-captionname',
      sortable: false,
    },

  ]);

  const refCurrentPage = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'search', 'offset_page');
  const checkedObj = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'grid', 'checked');
  const limit = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'pagination', 'limit');
  const totalPage = window.$util.computeGetSet(STORE_SYNC_SEARCH, 'pagination', 'total_page_num');
  const sort = ref(null);
  const dataItems = computed(() => window.$store.getters[`${STORE_SYNC_SEARCH}/dataItems`] || []);

  const gridItems = computed(() => {
    const startNum = limit.value * (refCurrentPage.value - 1) + 1;
    return dataItems.value.map((obj, idx) => ({
      no: startNum + idx,
      checked: !!checkedObj.value[obj.job_id],
      ...obj,
      // reg_dt: moment(obj.reg_dt).format('YYYY-MM-DD hh:mm'),
      brcast_dt: moment(obj.brcast_dt).format('YYYY-MM-DD'),
      tdStyle: {
        color: 'red',
      },
    }));
  });

  const checkedAll = computed(({
    get() {
      if (gridItems.value?.length === 0) return false;
      return !gridItems.value.find((obj) => !obj.checked);
    },
    set(value) {
      dataItems.value.forEach((obj) => {
        checkedObj.value[obj.job_id] = value;
      });
    },
  }));

  const getList = () => window.$store.dispatch(`${STORE_SYNC_SEARCH}/getList`);

  async function requestSyncInfo(id) {
    const res = await window.$axios.get('synclists/syncinfo', {
      params: {
        brcast_contents_seq: id,
      },
    });
    const { code, message } = await res.result;
    if (code !== 200) {
      window.$util.Error({
        url: 'synctoday/actions',
      });
      throw new Error(`${message} while requesting synctoday/actions`);
    }

    const { batch_job_histories, sync_job_histories } = res.caption;
    const batchJobs = batch_job_histories.map((obj) => ({
      ...obj,
      start_time: obj.start_time ? moment(obj.start_time).format('YYYY-MM-DD HH:mm') : undefined,
      end_time: obj.end_time ? moment(obj.end_time).format('YYYY-MM-DD HH:mm') : undefined,

    }));
    const syncJobs = sync_job_histories.map((obj) => ({
      ...obj,
      upd_dt: obj.upd_dt ? moment(obj.upd_dt).format('YYYY-MM-DD HH:mm') : undefined,

    }));

    return {
      ...res.caption, batchJobs, syncJobs,
    };
  }

  return {
    columns,
    checkedObj,
    refCurrentPage,
    gridItems,
    checkedAll,
    totalPage,
    requestSyncInfo,
    onSort(obj) {
      sort.value = obj;
    },
    onChangePage(idx) {
      refCurrentPage.value = idx;
      getList();
    },
  };
}
