<template>
  <div class="gridControlWrap multi">
    <div class="searchWrap">
      <CommonDatepicker v-model="date" />
    </div>
    <div class="searchWrap">
      <div class="searchBox">
        <CommonSelect
          v-model:options="SEARCH_TYPE"
          v-model:selected="refSearchType"
          placeholder="통합검색"
        />
        <CommonInput
          label="에피소드"
          v-model="refSearchValue"
          @keyup.enter="getList()"
        />
        <CommonInput
          label="채널명"
          v-model="refChannel"
          @keyup.enter="getList()"
        />
        <CommonButton
          class="btnSearch"
          @click="getList()"
        >
          검색
        </CommonButton>
        <CommonButton
          class="btnSearch btn-run"
          @click="onMappingClick"
          :disabled="!downloadable"
        >
          매핑실행
        </CommonButton>
      </div>
    </div>
  </div>

  <Teleport to="#modal">
    <ModalSubtitleMapping
      v-model="modalShow"
    />
  </Teleport>
</template>

<script setup>
import CommonDatepicker from '@/components/CommonDatepicker.vue';
import CommonButton from '@/components/CommonButton.vue';
import CommonSelect from '@/components/CommonSelect.vue';
import CommonInput from '@/components/CommonInput.vue';

import ModalSubtitleMapping from '@/views/Modal/ModalSubtitleMapping.vue';
import { computed, ref } from 'vue';
import useReSyncSearch from '@/views/ReSyncSearch/reSyncSearchSearch';
import { STORE_RESYNC_SEARCH } from '@/store/modules/ReSyncSearch';

const checkedObj = computed(() => window.$store.getters[`${STORE_RESYNC_SEARCH}/checkedItems`]);
const downloadable = computed(() => (!!checkedObj.value.length));
const SEARCH_TYPE = ref([
  {
    text: '에피소드명', value: 'TST001',
  },
  {
    text: '에피소드ID', value: 'TST002',
  },
]);

const modalShow = ref(false);
const {
  date,
  getList,
  refSearchType,
  refSearchValue,
  refChannel,
} = useReSyncSearch();

const onMappingClick = async () => {
  const checkedPrg = ref(window.$store.getters[`${STORE_RESYNC_SEARCH}/checkedItems`]);
  if (checkedPrg.value.length < 1) {
    window.$util.Toast('매핑할 프로그램을 선택하세요.');
    return;
  }
  window.$store.dispatch('CaptionSearch/beforeRouteEnter');
  await window.$store.dispatch('CaptionSearch/getList');
  modalShow.value = true;
};

</script>
<style lang="scss" scoped>
.searchBox{
  display: flex;
  align-items: center;
  .sort-term{
    margin:0 0.5rem 0 0;
  }
}
.gridControlWrap .searchWrap .searchBox .btnSearch.btn-run{
  background-color: #ffa900;
}
</style>
