<template>
  <CommonGrid
    class-name="remapped"
    :columns="columns"
    :datas="gridItems"
    @sort="onSort"
    :page-able="true"
    :page-idx="refCurrentPage"
    :total-page="totalPage"
    @change-page="onChangePage"
  >
    <template #header_checked>
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedAll"
      />
    </template>
    <template #checked="{brcast_contents_seq}">
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedObj[brcast_contents_seq]"
      />
    </template>
  </CommonGrid>
</template>

<script setup>
import CommonGrid from '@/components/CommonGrid.vue';

import useEncoderHistorySearchGrid from './reSyncSearchGrid';

const { columns,
  checkedObj,
  refCurrentPage,
  gridItems,
  checkedAll,
  totalPage,
  onSort,
  onChangePage } = useEncoderHistorySearchGrid();

</script>
<style lang="scss">
.remapped{
  .accoItem{
    min-width: 1400px;
    .form-check{
      flex: 0 0 50px;
      display: flex;
      justify-content: center;
    }
    .acco-number{
      flex: 0 0 56px;
    }
    .acco-channel{
      flex: 0 0 120px;
    }
    .acco-date{
      flex: 0 0 100px;
    }
    .acco-episodeid{
      flex: 0 0 150px;
    }
    .acco-episodename,
    .acco-filename{
      flex: 1 0 210px;
      text-align: left;
      word-break: break-all;
    }
    .acco-episodenumer{
      flex: 0 0 70px;
    }
  }
}
</style>
