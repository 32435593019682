<template>
  <LayoutBase>
    <template #chart>
      <LastUpdate />
      <Chart />
      <ButtonGroup />
    </template>

    <template #searchGrid>
      <SearchGrid />
    </template>
  </LayoutBase>
</template>

<script>
import LayoutBase from '@/components/layout/LayoutBase.vue';
import LastUpdate from './SyncStatus/SyncStatusLastUpdate.vue';
import Chart from './SyncStatus/SyncStatusChart.vue';
import SearchGrid from './SyncStatus/SyncStatusSearchGrid.vue';
import ButtonGroup from './SyncStatus/SyncStatusButtonGroup.vue';

export default {
  components: {
    LayoutBase,
    LastUpdate,
    Chart,
    SearchGrid,
    ButtonGroup,
  },
  // 네비게이트 전에 api 호출하게 함
  async beforeRouteEnter(to, from, next) {
    window.$store.dispatch('SyncStatus/beforeRouteEnter');
    await window.$store.dispatch('SyncStatus/getList');
    await window.$store.dispatch('SyncStatus/getChart');
    next();
  },
  beforeRouteLeave() {
    window.$store.dispatch('SyncStatus/beforeRouteLeave');
  },
};
</script>
