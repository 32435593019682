<template>
  <CommonGrid
    class-name="syncsearch"
    :columns="columns"
    :datas="gridItems"
    @sort="onSort"
    :page-able="true"
    :page-idx="refCurrentPage"
    :total-page="totalPage"
    @change-page="onChangePage"
    @row-click="onRowClick"
  >
    <template #header_checked>
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedAll"
      />
    </template>
    <template #checked="{job_id}">
      <MDBCheckbox
        aria-label="..."
        :form-check="true"
        class="accroCheck"
        v-model="checkedObj[job_id]"
        @click="(e)=>{e.stopPropagation();}"
      />
    </template>
    <template
      #sync_status="{sync_status , sync_server_result}"
    >
      <div
        class="acco-status"
        :class="{'text-danger' : sync_server_result === '실패' ,
                 'col-waiting': sync_status === '검수대기'}"
      >
        {{ sync_status }}
      </div>
    </template>
  </CommonGrid>

  <Teleport to="#modal">
    <ModalSyncStatusDetail
      v-model="modalShow"
      :caption="caption"
      vertical
    />
  </Teleport>
</template>

<script setup>
import CommonGrid from '@/components/CommonGrid.vue';
import ModalSyncStatusDetail from '@/views/Modal/ModalSyncStatusDetail.vue';
import { ref } from 'vue';
import useEncoderHistorySearchGrid from './syncSearchGrid';

const modalShow = ref(false);
const caption = ref({});
const { columns,
  checkedObj,
  refCurrentPage,
  gridItems,
  checkedAll,
  totalPage,
  requestSyncInfo,
  onSort,
  onChangePage } = useEncoderHistorySearchGrid();

async function onRowClick(event, item) {
  caption.value = await requestSyncInfo(item?.brcast_contents_seq);
  modalShow.value = caption.value && true;
}

</script>
<style lang="scss">
.syncsearch{
  .accoItem{
    min-width: 1400px;
    &.cell{
      cursor: pointer;
      transition: 0.3s;
      &:hover,
      &:nth-child(2n):hover{
        background: #e3e3e3;
      }
    }
    .form-check{
      flex: 0 0 50px;
      display: flex;
      justify-content: center;
    }
    .acco-number{
      flex: 0 0 56px;
    }
    .acco-type,
    .acco-date,
    .acco-status,
    .acco-channel{
      flex: 0 0 120px;
    }
    .acco-registration-date{
      flex: 0 0 180px;
    }
    .acco-series,
    .acco-episode,
    .acco-captionname
    {
      flex: 1 0 220px;
      text-align: left;
      word-break: break-all;
    }
  }
}
.btn-wrap{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  .btn{
    font-size: 14px;
  }
}
</style>
